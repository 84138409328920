import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { AGENT_RATE_NAME } from "../../utils/authUtils";
import SwitchUserModal from "../modals/SwitchUserModal";
import { getTitleByRoute } from "../../utils/routeTitles";
import useOutsideClick from "../../hooks/useOutsideClick";
import { AppDispatch, RootState } from "../../redux/store";
import { logoutUser } from "../../redux/reducer/auth/authSlice";
import { setTitle } from "../../redux/reducer/navbar/navbarSlice";
import { firstLetterCapital, capitalizeEachWord } from "../../utils/utils";
import { getApi } from "../../apis/api";
import { GET_PARENT_CUSTOMER_DATA } from "../../apis/end-points";
import { GENERAL_ERROR_MESSAGE } from "../../utils/message";
import {
  setActiveTabInnerChildTab,
  setPopFromPreviousTab,
  setActiveTab
} from "../../redux/reducer/activeTab/activeTabSlice";
import useFormChangeHandler from "../../hooks/useFormChangeHandler";

import {
  EditProfileIcon,
  LogoutProfileIcon,
  SwtichIcon,
  BackTitleIcon,
  MenuHeaderIcon,
  BackButtonIcon,
  AddIcon,
} from "../../assets";
import ConfirmModal from "../../components/modals/ConfirmModal";
import { setFormChanged } from "../../redux/reducer/prompt/promptSlice";

export interface AddedByTenantsTypes {
  customerName: string;
  customerId: string;
  customerNumber: string;
  id: string;
}
interface GetCustomerApiResponse {
  addedByTenants: AddedByTenantsTypes[];
  currentCustomer: AddedByTenantsTypes;
}
interface RouteWithBackButton {
  path: string | RegExp;
  activeTabs: string[];
  shouldNavigateBack: boolean;
  shouldNavigateToPath?: string;
}

// Array of routes where back button should be shown
const routesWithBackButton: RouteWithBackButton[] = [
  {
    path: /^\/agent-list\/[^/]+$/,
    // activeTabs: ["Weight Zone", "Agent Rates"],
    activeTabs: [],
    shouldNavigateBack: false,
    shouldNavigateToPath: "/agent-list",
  },
  {
    path: /^\/master-agent-list\/[^/]+$/,
    // activeTabs: ["Weight Zone", "Agent Rates"],
    activeTabs: [],
    shouldNavigateBack: false,
    shouldNavigateToPath: "/master-agent-list",
  },
  {
    path: /^\/customer\/rate(\/[^/]+)?$/,
    activeTabs: [],
    shouldNavigateBack: true,
    shouldNavigateToPath: "/customers",
  },
  {
    path: "/email-notification-permissions",
    activeTabs: [],
    shouldNavigateBack: true,
  },
  {
    path: /^\/customer-order-history\/([^/]+)?$/,
    activeTabs: [],
    shouldNavigateBack: true,
  },
];

const Navbar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch: AppDispatch = useDispatch();
  const [showProfile, setShowProfile] = useState<boolean>(false);
  const [openSwitchUserModal, setOpenSwitchUserModal] =
    useState<boolean>(false);
  const [agentName, setAgentName] = useState<string>(
    localStorage.getItem(AGENT_RATE_NAME) ?? ""
  );
  const { title, username, userProfile, userRole, userEmail, companyLogo } =
    useSelector((state: RootState) => state.navbar);

  const { activeTabInnerChildTab, previousInnerChildTabStack } = useSelector(
    (state: RootState) => state.activeTab
  );
  const { customerLevel } = useSelector((state: RootState) => state.auth);
  const [switchCustomerData, setSwitchCustomerData] = useState<
    AddedByTenantsTypes[]
  >([]);
  const [customerSelectedData, setCustomerSelectedData] =
    useState<AddedByTenantsTypes>({
      customerName: "",
      customerId: "",
      customerNumber: "",
      id: "",
    });
  const { isUserOfOmniMove } = useSelector((state: RootState) => state.auth);
  /**
   * Checks if the current location matches any route that requires a back button
   * and if the activeTabInnerChildTab has required values.
   * @returns {boolean} True if a match is found, otherwise false.
   */
  const shouldShowBackButton = routesWithBackButton.some((route) => {
    const routeMatches =
      typeof route.path === "string"
        ? location.pathname.includes(route.path)
        : route.path.test(location.pathname);

    const activeTabMatches =
      !route.activeTabs.length ||
      (activeTabInnerChildTab &&
        route.activeTabs.includes(activeTabInnerChildTab));

    return routeMatches && activeTabMatches;
  });
  // console.log(activeTabInnerChildTab);

  /**
   * Toggles the visibility of the profile dropdown.
   */
  const changeProfileView = () => {
    setShowProfile(!showProfile);
  };

  /**
   * Handles user logout by dispatching the `logoutUser` action and navigating to the sign-in page.
   */
  const handleLogout = () => {
    dispatch(logoutUser());
    navigate("/users/sign_in");
  };

  /**
   * Navigates to the user's profile edit page and closes the profile dropdown.
   */
  const handleUserEdit = () => {
    setShowProfile(false);
    navigate("/user/profile");
  };

  /**
   * Determines the behavior for back navigation based on the route's shouldNavigateBack value.
   */
  const handleNavigateBack = () => {
    dispatch(setFormChanged(false));
    const matchedRoute = routesWithBackButton.find((route) => {
      return typeof route.path === "string"
        ? location.pathname.includes(route.path)
        : route.path.test(location.pathname);
    });
    if (matchedRoute?.shouldNavigateBack) {
      if (matchedRoute?.shouldNavigateToPath) {
        navigate(matchedRoute?.shouldNavigateToPath);
      } else {
        navigate(-1);
      }
    } else {
      if (activeTabInnerChildTab) {
        if ([1, 0]?.includes(previousInnerChildTabStack.length)) {
          // If there is no existing tab, then set to null to remove backIcon
          dispatch(setActiveTabInnerChildTab(null));
        }
      } else {
        if (matchedRoute?.shouldNavigateToPath) {
          navigate(matchedRoute?.shouldNavigateToPath);
        } else {
          navigate(-1);
        }
      }

      previousInnerChildTabStack.length && dispatch(setPopFromPreviousTab());
    }
  };

  // Hook for handling form change navigation
  const {
    checkInputChange,
    handleNavigation,
    handleConfirmNavigation,
    handleCancelNavigation,
    handleUpdateNavigation,
    showModal,
    isFormChanged,
  } = useFormChangeHandler();

  /**
   * Handles the opening of the Switch User modal.
   */
  const handleSwitchUser = () => {
    setOpenSwitchUserModal(true);
  };

  // Ref to handle clicks outside the dropdown to close it
  const dropdownRef = useOutsideClick<HTMLDivElement>(() => {
    setShowProfile(false);
  });

  /**
   * Fetches the parent customer data from the API.
   * Updates the state with the retrieved data.
   */
  const getParentCustomerData = async () => {
    try {
      const response = await getApi<GetCustomerApiResponse>(
        GET_PARENT_CUSTOMER_DATA
      );
      if (response.success) {
        const data = response.data?.addedByTenants ?? [];
        const mappedData: AddedByTenantsTypes[] = data?.map((item) => ({
          id: item?.customerId,
          customerId: item?.customerId,
          customerName: item?.customerName ?? "",
          customerNumber: item?.customerNumber ?? "",
        }));
        setSwitchCustomerData(mappedData);
        const currentCustomer =
          response.data?.currentCustomer ??
          ({} as Partial<AddedByTenantsTypes>);
        setCustomerSelectedData({
          customerName: currentCustomer?.customerName ?? "",
          customerId: currentCustomer?.customerId ?? "",
          customerNumber: currentCustomer?.customerNumber ?? "",
          id: currentCustomer?.customerId ?? "",
        });
      }
    } catch (error) {
      console.error(GENERAL_ERROR_MESSAGE, error);
    }
  };

  /**
   * Handles sidebar collapse by toggling the 'sidebar-hidden' class.
   */
  const toggleSidebar = () => {
    document.body.classList.toggle("sidebar-hidden");
  };

  /**
   * Effect to update the page title based on the current route and agent name.
   *
   * @description This effect runs whenever the route changes, updating the navbar title
   * by dispatching the `setTitle` action with the title derived from the current path.
   */
  useEffect(() => {
    const currentTitle = getTitleByRoute(location.pathname, agentName);
    dispatch(setTitle(currentTitle));
  }, [location.pathname, dispatch, agentName]);

  /**
   * Effect to update the agent name from localStorage when it changes.
   */
  useEffect(() => {
    setAgentName(localStorage.getItem(AGENT_RATE_NAME) ?? "");
  }, [localStorage.getItem(AGENT_RATE_NAME)]);

  /**
   * Effect to fetch parent customer data when the component mounts.
   */
  useEffect(() => {
    getParentCustomerData();
  }, []);

  const BackButtonClick = () => {
    if (isFormChanged) {
      handleNavigation();
    } else {
      handleNavigateBack();
    }
  };

  return (
    <>
      <div className="page__head">
        <h2 className="page__head--title">
          {shouldShowBackButton && (
            <button className="backBtn">
              <BackTitleIcon onClick={BackButtonClick} />
            </button>
          )}
          {title}
        </h2>
        <div className="page__head__actions">
          <div className="btn__group">
                      {userRole !== "agent"&& userRole !== "superadmin" && isUserOfOmniMove === false && customerLevel !== "third" && (
                        <button
                          className="btn btn__white"
                          onClick={() => {
                            dispatch(setActiveTab(null));
                            navigate("/customer-order");
                          }}
                        >
                          <AddIcon />
                          Add New Order
                        </button>
                      )}
                    </div>
          <div className="userProfile">
            <button className="userProfile__link" onClick={changeProfileView}>
              <span className="picIcon">
                {customerLevel === "first" &&
                userRole === "admin" &&
                companyLogo ? (
                  <img className="picIcon__img" src={companyLogo} alt="" />
                ) : (
                  userProfile
                )}
              </span>
              {capitalizeEachWord(username ?? "")}
            </button>
            <div
              ref={dropdownRef}
              className={`userProfile__dropdown ${
                showProfile ? "userProfile__dropdown--open" : ""
              }`}
            >
              <div className="userdetials">
                <div className="userdetials__info">
                  <div className="pic">
                    {customerLevel === "first" &&
                    userRole === "admin" &&
                    companyLogo ? (
                      <img className="pic__img" src={companyLogo} alt="" />
                    ) : (
                      userProfile
                    )}
                  </div>
                  <h3 className="name">{capitalizeEachWord(username ?? "")}</h3>
                  <h4 className="role">
                    {firstLetterCapital(
                      customerLevel === "third" && userRole === "customer"
                        ? "Admin"
                        : userRole ?? ""
                    )}
                  </h4>
                  <p className="para">{userEmail}</p>
                </div>
                <div className="btn__group">
                  <div className="btn__group__item">
                    <button
                      type="button"
                      className="btn btn__primary"
                      onClick={handleUserEdit}
                    >
                      <EditProfileIcon />
                      Edit Profile
                    </button>
                  </div>
                  {!switchCustomerData?.length && (
                    <div className="btn__group__item">
                      <button
                        type="button"
                        onClick={handleLogout}
                        className="btn btn__danger"
                      >
                        <LogoutProfileIcon />
                        Logout
                      </button>
                    </div>
                  )}
                  {switchCustomerData &&
                    ((userRole === "agent" && switchCustomerData.length > 0) ||
                      (userRole != "agent" &&
                        switchCustomerData.length > 1)) && (
                      <div className="btn__group__item">
                        <button
                          type="button"
                          className="btn btn__primary"
                          onClick={handleSwitchUser}
                        >
                          <SwtichIcon />
                          Switch User
                        </button>
                      </div>
                    )}

                  {switchCustomerData.length > 0 && (
                    <div className="btn__group__item">
                      <button
                        type="button"
                        onClick={handleLogout}
                        className="btn btn__danger btn__outlined"
                      >
                        <LogoutProfileIcon />
                        Logout
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <button
            className="btnCollapse"
            id="sidebarCollapse"
            onClick={toggleSidebar}
          >
            <MenuHeaderIcon />
          </button>
        </div>
      </div>
      {openSwitchUserModal && (
        <SwitchUserModal
          handleClose={() => setOpenSwitchUserModal(false)}
          // switchCustomerData={switchCustomerData}
          // setSwitchCustomerData={setSwitchCustomerData}
          customerSelectedData={customerSelectedData}
          setCustomerSelectedData={setCustomerSelectedData}
        />
      )}
      {showModal && (
        <ConfirmModal
          confirmInfo={{
            heading: "Unsaved Changes!",
            para: "You have not saved your changes, do you want to continue",
          }}
          onConfirm={handleConfirmNavigation}
          setConfirmModal={handleCancelNavigation}
          firstMessageShow={true}
        />
      )}
    </>
  );
};

export default Navbar;
