import React, { useMemo, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import ConfirmModal from "../modals/ConfirmModal";
import { useMessage } from "../../context/MessageContext";
import {
  activePayloadApi,
  deleteApi,
  deletePayloadApi,
  getApi,
  postApi,
  putApi,
} from "../../apis/api";
import { RootState } from "../../redux/store";
import { AGENT_RATE_NAME } from "../../utils/authUtils";
import { GENERAL_ERROR_MESSAGE } from "../../utils/message";
import { setLocationData } from "../../redux/reducer/locationSlice/locationSlice";
import useOutsideClick from "../../hooks/useOutsideClick";
import DropdownModal, {
  AccordionField,
  AccordionItem,
} from "../modals/DropdownModal";
import httpService from "../../apis/axios-config/http-service";

import {
  ActiveIcon,
  ArrowDownLightIcon,
  ArrowUpLightIcon,
  BlueViewIcon,
  DeleteIcon,
  EditUserIcon,
  GreenDownloadIcon,
  NavigateIcon,
  ThreeDotIcon,
  UnactiveIcon,
  FileIcon,
  OrangeFileIcon, 
  BlueFileIcon
} from "../../assets";

interface ColumnTypes {
  key: string;
  header: string;
  isSortable: boolean;
  className?: string;
  isActive?: boolean;
  handleActive?: boolean;
  upperCase?: boolean;
  dolloar?: boolean;
  checkbox?: boolean;
  checkboxId?: string;
}

interface ActionConfigTypes {
  apiUrl: string;
  urlID: string;
  tenantIdUrl?: string;
  successMessage?: string;
  heading?: string;
  para?: string;
  extra?: string;
  rowName?: string;
  secondRowName?: string;
  onboardedMessage?: string;
  deleteByPayload?: boolean;
  activeByPayload?: boolean;
  deleteByPayloadKeys?: string[];
  activeByPayloadKeys?: string;
  payloadKeys?: any;
  changeStateToView?: (hubStep: number, data: any) => void;
  notCheckedOnboarded?: boolean;
  extendedParams?: string[];
}

interface NavigateConfigTypes {
  apiUrl: string;
  urlID: string;
  stateData?: boolean;
  changeStateToEdit?: (hubStep: number, data: any) => void;
}

interface InvoiceConfigTypes {
  changeStateToEdit?: (data: any) => void;
}

interface AddConfigTypes {
  heading: string;
  confirmationText: string;
  successMessage: string;
  apiUrl: string;
  urlID: string;
  payloadKeys: any;
  viewUrl: string;
  viewUrlID: string;
}
interface DropdownItemTypes {
  name: string;
  icon?: React.ReactElement;
  apiUrl: string;
  serialNumber: string;
  accordionFields?: AccordionField;
  mappingFunction?: (data: any) => AccordionItem[];
  downloadInvoice?: {
    apiUrl: string;
    fileName: string;
  };
  navigateItem?: {
    path: string;
    isCheckNavigateByKey: string;
    showErrorMessage: string;
    urlParamKey: string;
  };
}

interface ManageConfigTypes {
  dropdownName: DropdownItemTypes[];
}

interface TablePropsTypes {
  columns: ColumnTypes[];
  data: any[];
  downlaodIcon?: boolean;
  viewIcon?: boolean;
  deleteIcon?: boolean;
  editIcon?: boolean;
  activeIcon?: boolean;
  disabledActive?: boolean;
  disableInactive?: boolean;
  addIcon?: boolean;
  setLoading: (loading: boolean) => void;
  onEditNaviagte?: NavigateConfigTypes;
  onDeleteConfig?: ActionConfigTypes;
  onActiveConfig?: ActionConfigTypes;
  resendInviteConfig?: ActionConfigTypes;
  from?: string;
  onViewConfig?: ActionConfigTypes;
  addConfig?: AddConfigTypes;
  onCustomerNameNaviagte?: NavigateConfigTypes;
  onCustomerNamePopUp?: (showModal: boolean, rowData: any) => void;
  getApiData?: (currentPage: number) => void;
  tenantId?: string;
  doSomethingOnParent?: (id: string) => void;
  profileLinkNaviagte?: NavigateConfigTypes;
  editModalHanlde?: (data: any) => void;
  manageIcon?: boolean;
  manageConfig?: ManageConfigTypes;
  oneRecordNotDelete?: boolean;
  invoiceIcon?: boolean;
  invoiceConfig?: any;
  orderIcon?: boolean;
  orderConfig?: InvoiceConfigTypes;
  checkboxDisable?: boolean;
  checkedIds?: string[];
  setCheckedIds?: (data: any) => void;
}

interface ConfirmTextTypes {
  heading: string;
  para: string;
  extra: string;
}

const Table: React.FC<TablePropsTypes> = ({
  columns,
  data,
  downlaodIcon,
  viewIcon,
  deleteIcon,
  editIcon,
  activeIcon,
  addIcon,
  setLoading,
  onEditNaviagte,
  onDeleteConfig,
  onActiveConfig,
  resendInviteConfig,
  onViewConfig,
  addConfig,
  onCustomerNameNaviagte,
  onCustomerNamePopUp,
  getApiData,
  tenantId,
  doSomethingOnParent,
  profileLinkNaviagte,
  editModalHanlde,
  manageIcon,
  from,
  manageConfig,
  disabledActive,
  disableInactive,
  oneRecordNotDelete = false,
  invoiceIcon,
  orderIcon,
  orderConfig,
  checkboxDisable = false,
  checkedIds,
  setCheckedIds
}) => {
  const { setMessage } = useMessage();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: string;
  } | null>(null);
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [reInvite, setReInvite] = useState<boolean>(false);
  const [confirmText, setConfirmText] = useState<ConfirmTextTypes>({
    heading: "",
    para: "",
    extra: "",
  });
  const [confirmSuccessText, setConfirmSuccessText] = useState<string>("");
  const [rowData, setRowData] = useState<any | null>(null);
  const [rowHandlerType, setRowHandlerType] = useState<string>("");
  const { currentPage } = useSelector((state: RootState) => state.pagination);
  // state to store row id for open dropdown of list
  const [openDropDownById, setOpenDropDownById] = useState<string>("");
  // To store the clicked config for the modal
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [currentConfig, setCurrentConfig] = useState<DropdownItemTypes | null>(
    null
  );
  const [selectAllChecked, setSelectAllChecked] = useState<boolean>(false);

  const sortedData = useMemo(() => {
    let sortableData = [...data];
    if (sortConfig !== null) {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [data, sortConfig]);

  const requestSort = (key: string) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key: string) => {
    if (!sortConfig) return <ArrowDownLightIcon />;
    return sortConfig.key === key ? (
      sortConfig.direction === "ascending" ? (
        <ArrowUpLightIcon />
      ) : (
        <ArrowDownLightIcon />
      )
    ) : (
      <ArrowDownLightIcon />
    );
  };

  // function to naviagte page
  const handleNavigatLink = (url: string, urlID: string) => {
    navigate(`${url}${urlID}`);
  };

  // function to naviagte page
  const handleNavigatLinkWithState = (
    url: string,
    urlID: string,
    data: any
  ) => {
    navigate(`${url}${urlID}`, { state: { data } });
    dispatch(setLocationData(data));
  };

  // function to store action buttons
  const handleAction = (
    row: any,
    actionType: string,
    confirmMsgheading: string,
    confirmMsgheadPara: string,
    successMsg: string,
    extraMsg: string
  ) => {
    setRowData(row);
    setRowHandlerType(actionType);
    setConfirmText({
      heading: confirmMsgheading,
      para: confirmMsgheadPara,
      extra: extraMsg,
    });
    setConfirmSuccessText(successMsg);
    setConfirmModal(true);
  };

  // function to set delete information
  const handleDelete = (row: any) => {
    handleAction(
      row,
      "Delete",
      onDeleteConfig?.heading || "Delete!",
      `delete ${onDeleteConfig?.rowName && row[onDeleteConfig?.rowName]} ${
        (onDeleteConfig?.secondRowName && row[onDeleteConfig?.secondRowName]) ||
        ""
      }`,
      onDeleteConfig?.successMessage || "",
      ""
    );
  };

  // function to set active information
  const handleActiveUsers = async (row: any) => {
    if (!row?.isSignedUp && !onActiveConfig?.notCheckedOnboarded) {
      // setMessage(
      //   `${onActiveConfig?.onboardedMessage || ""} is not onboarded yet!`,
      //   "error"
      // );
      setReInvite(true);
      handleAction(
        row,
        "Resend",
        resendInviteConfig?.heading || "Delete!",
        `re-invite this ${
          onDeleteConfig?.rowName && row[onDeleteConfig?.rowName]
        } ${
          (onDeleteConfig?.secondRowName &&
            row[onDeleteConfig?.secondRowName]) ||
          ""
        }`,
        resendInviteConfig?.successMessage || "",
        resendInviteConfig?.extra || ""
      );
    } else {
      const action = row.active ? "Deactivate" : "Activate";
      const paraAction = row.active ? "deactivate" : "activate";
      handleAction(
        row,
        "Active",
        `${action} ${onActiveConfig?.heading}`,
        `${paraAction} the ${
          onActiveConfig?.rowName && row[onActiveConfig?.rowName]
        } ${
          (onActiveConfig?.secondRowName &&
            row[onActiveConfig?.secondRowName]) ||
          ""
        }`,
        `${onActiveConfig?.successMessage} ${
          row.active ? "deactivated" : "activated"
        } successfully!`,
        ""
      );
    }
  };

  // function to hit api
  const handleConfirmAction = async () => {
    setReInvite(false);
    if (!rowData) return;
    setConfirmModal(false);
    setLoading(true);
    try {
      const configMap: { [key: string]: ActionConfigTypes | undefined } = {
        Delete: onDeleteConfig,
        Active: onActiveConfig,
        Add: addConfig,
        Resend: resendInviteConfig,
      };
      const currentConfig = configMap[rowHandlerType];
      if (!currentConfig) {
        setMessage(GENERAL_ERROR_MESSAGE, "error");
        setLoading(false);
        return;
      }

      let apiUrl = `${currentConfig.apiUrl}${rowData[currentConfig.urlID]}`;
      let payload: any = {};
      if (tenantId && rowData?.tenantId) {
        apiUrl = `${apiUrl}/${rowData?.tenantId}`;
      }
      if (currentConfig?.deleteByPayload) {
        apiUrl = `${currentConfig.apiUrl}`;
        currentConfig?.deleteByPayloadKeys &&
          currentConfig?.deleteByPayloadKeys.length &&
          currentConfig.deleteByPayloadKeys.map((item) => {
            payload[item] = rowData?.[item];
          });
      }
      if (currentConfig?.activeByPayload) {
        apiUrl = `${currentConfig.apiUrl}`;
        payload = {
          [currentConfig.activeByPayloadKeys || ""]:
            rowData?.[currentConfig.urlID],
          isActive: !rowData.active ? "true" : "false",
        };
      }
      if (currentConfig?.payloadKeys && currentConfig.payloadKeys?.length) {
        apiUrl = `${currentConfig.apiUrl}`;
        currentConfig.payloadKeys.map((item: string) => {
          payload[item] = rowData?.[item];
        });
      }
      if (currentConfig?.extendedParams) {
        apiUrl = apiUrl + "?";
        currentConfig?.extendedParams.map((item, index) => {
          const andMarkCheck = index === 0 ? "" : "&";
          apiUrl = apiUrl + `${andMarkCheck}${item}=${rowData[item] || ""}`;
        });
      }
      if (rowHandlerType === "Resend" && resendInviteConfig) {
        // Set the payload for Resend
        payload = {
          [resendInviteConfig?.urlID]: rowData[resendInviteConfig?.urlID],
        };
      }
      if (from) {
        payload["from"] = from;
      }
      const response =
        rowHandlerType === "Delete"
          ? currentConfig.deleteByPayload
            ? await deletePayloadApi(apiUrl, { data: payload })
            : await deleteApi(apiUrl)
          : rowHandlerType === "Add"
          ? await postApi(apiUrl, payload)
          : rowHandlerType === "Resend"
          ? await postApi(currentConfig.apiUrl, payload)
          : currentConfig.activeByPayload
          ? await activePayloadApi(apiUrl, payload)
          : await putApi(apiUrl, {
              isActive: !rowData.active ? "true" : "false",
            });

      if (response.success) {
        setMessage(confirmSuccessText, "success");
        getApiData && getApiData(currentPage);

        // Redirect URL
        if (addConfig?.viewUrl) {
          handleNavigatLink(addConfig.viewUrl, "");
        }
      } else {
        setMessage(response.error?.message ?? GENERAL_ERROR_MESSAGE, "error");
      }
    } catch (error) {
      setMessage(GENERAL_ERROR_MESSAGE, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleAddAction = (row: any) => {
    handleAction(
      row,
      "Add",
      addConfig?.heading ? addConfig.heading : "",
      addConfig?.confirmationText ? addConfig.confirmationText : "",
      addConfig?.successMessage ? addConfig.successMessage : "",
      ""
    );
  };

  // Ref to handle clicks outside the dropdown to close it
  const dropdownRef = useOutsideClick<HTMLUListElement>(() => {
    setOpenDropDownById("");
  });

  // Handle dropdown click to open modal with the specific item config
  const handleDropdownClick = async (
    dropdownItem: DropdownItemTypes,
    rowData: any
  ) => {
    if (dropdownItem.accordionFields) {
      setCurrentConfig(dropdownItem); // Set only the clicked item's config for the modal
      setModalVisible(true); // Open the modal
    } else if (dropdownItem.downloadInvoice) {
      try {
        setLoading(true);
        // Get serial number from the row data
        const serialNumber = rowData[dropdownItem?.serialNumber];
        const fileName = `Invoice-Number-${
          rowData[dropdownItem?.downloadInvoice?.fileName]
        }.pdf`;

        const response = await httpService.get(
          `/order/invoice/download/${serialNumber}`,
          {
            responseType: "blob", // Ensures the response is treated as binary (Blob)
          }
        );
        if (response) {
          const blob = response;
          // Detect Safari to use a different method
          const isSafari = /^((?!chrome|android).)*safari/i.test(
            navigator.userAgent
          );

          if (isSafari) {
            // Safari workaround using FileReader
            const reader = new FileReader();
            reader.onloadend = function () {
              const link = document.createElement("a");
              link.href = reader.result as string;
              link.download = fileName;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            };
            reader.readAsDataURL(blob); // Read blob as Data URL
          } else {
            // Standard method for other browsers
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(downloadUrl); // Clean up the object URL
          }
          setMessage("Invoice downloaded successfully!", "success");
        } else {
          setMessage(GENERAL_ERROR_MESSAGE, "error");
        }
      } catch (error) {
        console.error("Error downloading the invoice:", error);
        setMessage(GENERAL_ERROR_MESSAGE, "error");
      } finally {
        setLoading(false);
      }
    } else if (dropdownItem.navigateItem) {
      const key = dropdownItem.navigateItem.isCheckNavigateByKey;
      const apiUrl = dropdownItem.apiUrl;
      const urlParamKey = dropdownItem.navigateItem.urlParamKey;
      const urlParamKeyId = rowData?.[dropdownItem.serialNumber];
      if (dropdownItem.navigateItem?.isCheckNavigateByKey === "") {
        dispatch(setLocationData(rowData));
        navigate(`${dropdownItem.navigateItem.path}`);
      } else {
        try {
          if (!apiUrl) {
            return;
          }
          setLoading(true);
          const response = await getApi(
            `${apiUrl}?${urlParamKey}=${urlParamKeyId}`
          );
          if (response.success && response.data) {
            const data: any = response.data;
            if (!data?.[key]) {
              setMessage(
                `${
                  dropdownItem.navigateItem?.showErrorMessage ||
                  "No Finalized Data Order Found!"
                }`,
                "error"
              );
            } else {
              dispatch(setLocationData(rowData));
              navigate(`${dropdownItem.navigateItem.path}`);
            }
          } else {
            setMessage(
              response.error?.message ?? GENERAL_ERROR_MESSAGE,
              "error"
            );
          }
        } catch (error) {
          console.error("Error downloading the invoice:", error);
          setMessage(GENERAL_ERROR_MESSAGE, "error");
        } finally {
          setLoading(false);
        }
      }
    }
  };

 /**
 * Handles "Select All" checkbox change.
 * When clicked, either selects or deselects all rows.
 */
  const handleSelectAllChange = (
    newStatus: boolean,
    setHasChanged: boolean
  ) => {
    const newSelectAllChecked = newStatus;
    setSelectAllChecked(newSelectAllChecked);
    if(newSelectAllChecked){
      let updatedData = data.map((row) => row.id);
      setCheckedIds && setCheckedIds(updatedData);
    }
    else{
      setCheckedIds && setCheckedIds([]);
    }
    
    /*
    setData(updatedData);
    if (setHasChanged) {
      setHasChanges && setHasChanges(true);
    }
    checkAmountChange && checkAmountChange(true);
    */
  };

/**
 * Handles checkbox change for a row.
 * When checked, sets isModified to true, when unchecked, sets it to false.
 * @param rowIndex - Index of the row being modified.
 */
  const handleCheckboxChange = (rowId: string) => {
    setCheckedIds && setCheckedIds((prevData:any) => {
      const newCheckedIds = prevData.includes(rowId)
        ? prevData.filter((item:string) => item !== rowId) // Remove item
        : [...prevData, rowId]; // Add item
      return newCheckedIds;
    });
  };

  useEffect(()=>{
    if(checkedIds?.length && checkedIds?.length === data?.length){
      setSelectAllChecked(true);
    }
    else{
      setSelectAllChecked(false);
    }
  }, [checkedIds])

  return (
    <>
      <div className="tableContainer">
        <table className="table">
          <thead>
            <tr>
              {columns.map((column) => (
                <th key={column.key}>
                  {column.checkbox ? (
                    <input
                      type="checkbox"
                      checked={selectAllChecked}
                      onChange={() =>
                        handleSelectAllChange(!selectAllChecked, true)
                      }
                      disabled={checkboxDisable}
                    />
                  ) :
                  column.isSortable ? (
                    <div
                      className="sortingDiv"
                      onClick={() =>
                        column.isSortable && requestSort(column.key)
                      }
                    >
                      {column.header}
                      {column.isSortable && (
                        <span className="sortingDiv__icon">
                          {getSortIcon(column.key)}
                        </span>
                      )}
                    </div>
                  ) : column.isActive ? (
                    activeIcon && (
                      <div
                        className="sortingDiv sortingDiv--center"
                        onClick={() =>
                          column.isSortable && requestSort(column.key)
                        }
                      >
                        {column.header}
                        {column.isSortable && (
                          <span className="sortingDiv__icon">
                            {getSortIcon(column.key)}
                          </span>
                        )}
                      </div>
                    )
                  ) : (
                    column.header
                  )}
                </th>
              ))}
              {orderIcon && <th style={{textAlign: "right"}}>Action</th>}
              {deleteIcon && editIcon && manageIcon && <th>&nbsp;</th>}
            </tr>
          </thead>
          <tbody>
            {sortedData.length === 0 ? (
              <tr>
                <td colSpan={columns.length + 1}>
                  <div className="no-data floating">No Results Found!</div>
                </td>
              </tr>
            ) : (
              sortedData.map((row, index) => (
                <tr key={index}>
                  {columns.map((column) => (
                    <td key={column.key}>
                      { column?.checkbox ? (
                        <input
                          type="checkbox"
                          checked={checkedIds && checkedIds.includes(row?.id)}
                          onChange={() => handleCheckboxChange(row?.id)}
                          disabled={checkboxDisable}
                        />
                      ) :
                      column.isActive && activeIcon ? (
                        row[column.key] ? (
                          <div
                            className="actions flex--center"
                            onClick={() => {
                              if (!disabledActive && !disableInactive) {
                                if (column.handleActive) {
                                  handleActiveUsers(row);
                                }
                              }
                            }}
                          >
                            <button className="btn p_0 border_0">
                              <ActiveIcon />
                            </button>
                          </div>
                        ) : (
                          <div className="actions flex--center">
                            <button
                              className="btn p_0 border_0"
                              onClick={() => {
                                if (!disabledActive) {
                                  if (column.handleActive) {
                                    handleActiveUsers(row);
                                  }
                                }
                              }}
                            >
                              <UnactiveIcon />
                            </button>
                          </div>
                        )
                      ) : column?.className === "link" ? (
                        <div className="profile">
                          <span
                            className="profile__name profile__name--underline"
                            onClick={() => {
                              if (onCustomerNameNaviagte) {
                                // onCustomerNameNaviagte(true);
                                localStorage.setItem(
                                  AGENT_RATE_NAME,
                                  row?.customerName
                                );
                                handleNavigatLink(
                                  onCustomerNameNaviagte?.apiUrl,
                                  row?.[onCustomerNameNaviagte?.urlID]
                                );
                              }
                              if (onCustomerNamePopUp) {
                                onCustomerNamePopUp(true, row);
                              }
                            }}
                          >
                            {row[column.key]}
                            <NavigateIcon />
                          </span>
                        </div>
                      ) : column?.className === "profile" ? (
                        <div className="profile">
                          {row?.logo ? (
                            <div className="profile__image">
                              <img src={row?.logo} alt={row[column.key]?.[0]} />
                            </div>
                          ) : (
                            <div className="profile__initials uppercase">
                              {row[column.key]?.[0]}
                            </div>
                          )}
                          <span className="profile__name">
                            {row[column.key]}
                          </span>
                        </div>
                      ) : column?.className === "profileWithNavigate" ? (
                        <div
                          className="profile"
                          onClick={() => {
                            if (profileLinkNaviagte) {
                              localStorage.setItem(
                                AGENT_RATE_NAME,
                                row?.agentName
                              );
                              handleNavigatLinkWithState(
                                profileLinkNaviagte?.apiUrl,
                                row?.[profileLinkNaviagte?.urlID],
                                row
                              );
                            }
                          }}
                        >
                          {row?.logo ? (
                            <div className="profile__image">
                              <img src={row?.logo} alt={row[column.key]?.[0]} />
                            </div>
                          ) : (
                            <div className="profile__initials uppercase">
                              {row[column.key]?.[0]}
                            </div>
                          )}
                          <span
                            className="profile__name"
                            style={{ textDecoration: "underline" }}
                          >
                            {row[column.key]}
                          </span>
                        </div>
                      ) : column?.className === "profileLink" ? (
                        <div
                          className="profile"
                          onClick={() => {
                            if (profileLinkNaviagte) {
                              localStorage.setItem(
                                AGENT_RATE_NAME,
                                row?.agentName
                              );
                              handleNavigatLinkWithState(
                                profileLinkNaviagte?.apiUrl,
                                row?.[profileLinkNaviagte?.urlID],
                                row
                              );
                            }
                          }}
                        >
                          <div className="profile__initials uppercase">
                            {row[column.key]?.[0]}
                          </div>
                          <span
                            className="profile__name"
                            style={{ textDecoration: "underline" }}
                          >
                            {row[column.key]}
                          </span>
                        </div>
                      ) : column?.className === "count" ? (
                        <div className="cellText">
                          <span className="cellText__dark cellText__dark--gray">
                            {row[column.key]}
                          </span>
                        </div>
                      ) : column?.className === "lastName" ? (
                        <div className="cellText">
                          <span className="cellText__primary">
                            {row[column.key]}
                          </span>
                        </div>
                      ) : column?.className === "addNote" ? (
                        <span
                          className="profile__name profile__name--underline"
                          onClick={() =>
                            doSomethingOnParent && doSomethingOnParent(row?.id)
                          }
                        >
                          {`${
                            row[column.key] == "" ? "+Add Note" : "Edit Note"
                          }`}
                        </span>
                      ) : column?.upperCase ? (
                        <div className="cellText">
                          <span className="cellText__dark capitalize">
                            {row[column.key]}
                          </span>
                        </div>
                      ) : column?.dolloar ? (
                        <div className="cellText">
                          <span className="cellText__dark capitalize">
                            ${Number(row[column.key])?.toFixed(2)}
                          </span>
                        </div>
                      ) : (
                        <div className="cellText">
                          <span className="cellText__dark">
                            {row[column.key]}
                          </span>
                        </div>
                      )}
                    </td>
                  ))}
                  {downlaodIcon ||
                  viewIcon ||
                  editIcon ||
                  deleteIcon ||
                  manageIcon ||
                  addIcon || 
                  invoiceIcon || 
                  orderIcon ? (
                    <td>
                      <div className="actions flex--end">
                        {downlaodIcon && (
                          <button className="btn p_0 border_0">
                            <GreenDownloadIcon />
                          </button>
                        )}
                        {viewIcon && (
                          <button
                            className="btn p_0 border_0"
                            onClick={() => {
                              if (onViewConfig) {
                                if (tenantId) {
                                  handleNavigatLink(
                                    onViewConfig?.apiUrl,
                                    `${row?.[onViewConfig?.urlID]}/${
                                      row?.tenantId
                                    }`
                                  );
                                } else if (onViewConfig?.changeStateToView) {
                                  onViewConfig?.changeStateToView(2, {
                                    [onViewConfig?.urlID]:
                                      row[onViewConfig?.urlID],
                                    [onViewConfig?.tenantIdUrl || ""]:
                                      row?.[onViewConfig?.tenantIdUrl || ""],
                                  });
                                } else {
                                  handleNavigatLink(
                                    onViewConfig?.apiUrl,
                                    row?.[onViewConfig?.urlID]
                                  );
                                }
                              }
                            }}
                          >
                            <BlueViewIcon />
                          </button>
                        )}
                        {editIcon && (
                          <button
                            disabled={row?.disableEditIcon}
                            className="btn p_0 border_0"
                            onClick={() => {
                              if (onEditNaviagte) {
                                if (tenantId) {
                                  handleNavigatLink(
                                    onEditNaviagte?.apiUrl,
                                    `${row?.[onEditNaviagte?.urlID]}/${
                                      row?.tenantId
                                    }`
                                  );
                                } else if (onEditNaviagte?.stateData) {
                                  handleNavigatLinkWithState(
                                    onEditNaviagte?.apiUrl,
                                    row?.[onEditNaviagte?.urlID],
                                    row
                                  );
                                } else if (onEditNaviagte?.changeStateToEdit) {
                                  onEditNaviagte?.changeStateToEdit(2, {
                                    [onEditNaviagte?.urlID]:
                                      row[onEditNaviagte?.urlID],
                                    serviceId: row?.serviceId || "",
                                  });
                                } else {
                                  handleNavigatLink(
                                    onEditNaviagte?.apiUrl,
                                    row?.[onEditNaviagte?.urlID]
                                  );
                                  localStorage.setItem(
                                    AGENT_RATE_NAME,
                                    row?.agentName
                                  );
                                }
                              } else if (editModalHanlde) {
                                editModalHanlde(row);
                              }
                            }}
                          >
                            <EditUserIcon />
                          </button>
                        )}
                        {deleteIcon && (
                          <button
                            className="btn p_0 border_0"
                            onClick={() => handleDelete(row)}
                            disabled={
                              oneRecordNotDelete &&
                              row?.parentCustomerId &&
                              data.filter((a) => a.id)?.length === 1
                                ? true
                                : false
                            }
                          >
                            <DeleteIcon />
                          </button>
                        )}
                        {invoiceIcon && (
                          <OrangeFileIcon />
                        )}
                        {orderIcon && (
                          <button
                            className="btn p_0 border_0"
                            onClick={()=>{
                              if(orderConfig && orderConfig?.changeStateToEdit){
                                orderConfig?.changeStateToEdit(row)
                              }
                            }} 
                          >
                            <BlueFileIcon />
                          </button>
                        )}
                        {addIcon &&
                          (row?.alreadyAdded ? (
                            <button
                              className="btn btn__primary"
                              onClick={() => {
                                addConfig &&
                                  // handleNavigatLink(
                                  //   addConfig && addConfig?.viewUrl,
                                  //   row?.[addConfig?.viewUrlID]
                                  // );
                                  handleNavigatLinkWithState(
                                    addConfig?.viewUrl,
                                    row?.[addConfig?.viewUrlID],
                                    row
                                  );
                              }}
                            >
                              View
                            </button>
                          ) : (
                            <button
                              className="btn btn__primary"
                              onClick={() => {
                                addConfig && handleAddAction(row);
                              }}
                            >
                              + Add
                            </button>
                          ))}
                        {manageIcon && (
                          <ul className="dropdown">
                            <li className="dropdown__item">
                              <button
                                className="btn p_0 border_0"
                                onClick={() => {
                                  // Open dropdown only for the current row
                                  setOpenDropDownById(
                                    openDropDownById === row?.id ? "" : row?.id
                                  );
                                }}
                              >
                                <ThreeDotIcon />
                              </button>
                              <ul
                                className={`dropdownMenu dropdownMenu${
                                  openDropDownById === row?.id ? "--open" : ""
                                }`}
                                ref={
                                  openDropDownById === row?.id
                                    ? dropdownRef
                                    : null
                                }
                              >
                                {manageConfig?.dropdownName?.map(
                                  (item, dropdownIndex: number) => (
                                    <li
                                      className="dropdown__item"
                                      key={`dropdown-${dropdownIndex}`}
                                      onClick={() =>
                                        handleDropdownClick(item, row)
                                      }
                                    >
                                      <span className="icon">{item?.icon}</span>
                                      {item?.name}
                                    </li>
                                  )
                                )}
                              </ul>
                            </li>
                          </ul>
                        )}
                      </div>
                    </td>
                  ) : (
                    ""
                  )}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      {confirmModal && (
        <ConfirmModal
          confirmInfo={confirmText}
          onConfirm={handleConfirmAction}
          setConfirmModal={setConfirmModal}
          reInvite={reInvite}
          setReInvite={setReInvite}
        />
      )}
      {modalVisible && currentConfig && (
        <DropdownModal
          onClose={() => setModalVisible(false)}
          config={currentConfig}
          id={openDropDownById}
        />
      )}
    </>
  );
};

export default Table;
