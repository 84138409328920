import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import {
  FilterModal,
  MultiSelectChips,
  MutliDropdown,
  Table,
} from "../../../components/shared";
import { Loader } from "../../../components/loaders/loaders";
import { useMessage } from "../../../context/MessageContext";
import { AddEditAgentField } from "../../agent-management/AgentTypes";
import { addEditAgentFormSchema } from "../../../components/schema/schema";
import { RootState, AppDispatch } from "../../../redux/store";
import { getApi, postApi, putApi, deleteApi } from "../../../apis/api";
import {
  CountryCodeApiResponse,
  customerOrderColumns,
} from "../../customer-management/CustomerTypes";
import { CustomerRowDataType } from "../../Order-management/CustomerOrder/CustomerOrder-types";
import EmailReportModal from "../../../components/modals/EmailReportModal";
import {
  GET_COUNTRY_CODE,
  ADD_EDIT_AGENT,
  GET_AGENT_TYPE,
  GET_CUSTOMER_KEY,
  GET_REPORT_COLUMNS,
  ADD_CUSTOMER,
} from "../../../apis/end-points";
import { setCountryCode } from "../../../redux/reducer/countryCode/countryCodeSlice";
import ConfirmModal from "../../../components/modals/ConfirmModal";
import SearchInput from "../../../components/shared/SearchInput";
import useFormChangeHandler from "../../../hooks/useFormChangeHandler";
import { GENERAL_ERROR_MESSAGE } from "../../../utils/message";
import SavedReports from "../SavedReports";
import { AddReport } from "./CreateReport-types";
import { template, values } from "lodash";
import SuccessModal from "../../../components/modals/SuccessModal";
import useSearchAndFilter from "../../../hooks/useSearchAndFilter";
import moment from "moment";
import { DropwdownValueTypes } from "../../agent-management/AgentList/AgentListTabs/AgentRateNew/AgentRateTypes";

const mapReportList = (reportList: any[]) => {
  if (reportList) {
    return reportList?.map((item: any, index: any) => ({
      count: item.count,
      id: "/" + item.id,
      createdOn: moment(item?.createdAt?.split("T")[0]).format("MM-DD-YYYY"),
      createdAt: moment(item?.createdAt.split("T")[1], "HH:mm:ss.SSSZ").format(
        "hh:mm A"
      ),
      title: item.title,
      createdBy:
        item.createdBy !== null
          ? item.createdBy.firstName + " " + item.createdBy.lastName
          : "",
      customerName: item.customer.name,
      customerType: item?.customer?.customerLevel
        ? item?.customer?.customerLevel === "first"
          ? "Direct Customer"
          : item?.customer?.customerLevel === "second"
          ? "Parent Customer"
          : "Sub Customer"
        : "",
      scheduling: item.scheduling,
      type: item.type,
    }));
  } else {
    return [];
  }
};

const CreateReport: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { setMessage } = useMessage();
  const dispatch: AppDispatch = useDispatch();
  // const [loading, setLoading] = useState<boolean>(false);
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [formData, setFormData] = useState<any>({});
  const [agentType, setAgentType] = useState<any>([]);
  const [columnData, setColumnData] = useState<any>([]);
  const [reportData, setReportData] = useState<any>([]);
  const [askEmail, setAskEmail] = useState(false);
  const [isCustom, setIsCustom] = useState(false);
  const [tempSelectedColumns, setTempSelectedColumns] = useState([
    {
      id: "N2GrsiqUJ5A",
      updatedAt: "2024-11-27T12:23:34.000Z",
      createdAt: "2024-11-27T12:00:59.000Z",
      title: "Order Number",
      key: "orderNumber",
      isActive: true,
    },
  ]);
  const today = moment().format("MM-DD-YYYY");
  const afterOneYear = moment().add(1, "year").format("MM-DD-YYYY");
  const maxDate = moment().format("YYYY-MM-DD");
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState([
    {
      id: "N2GrsiqUJ5A",
      updatedAt: "2024-11-27T12:23:34.000Z",
      createdAt: "2024-11-27T12:00:59.000Z",
      title: "Order Number",
      key: "orderNumber",
      isActive: true,
    },
  ]);
  const [success, setSuccess] = useState(false);
  const [saveAndSend, setSaveAndSend] = useState(false);
  const { data, fetchFilteredData, loading, setLoading } = useSearchAndFilter({
    apiUrl: "/report",
    limit: 5,
    mapUserData: mapReportList,
    extentedUrl: "&type=recent",
  });
  const { userRole, customerId, isUserOfOmniMove, customerLevel, isCustomer } =
    useSelector((state: RootState) => state.auth);
  const [associatedCusReqired, setAssociatedCusReqired] = useState<boolean>(
    !isUserOfOmniMove && isCustomer ? false : true
  );
  const [parentCustomerId, setParentCustomerId] = useState<string>();
  const locationStateData = useSelector(
    (state: RootState) => state.locationState.locationStateData
  );
  const [customerSelected, setCustomerSelected] = useState<
    DropwdownValueTypes[]
  >([]);
  const [associatedCustomerSelected, setAssociatedCustomerSelected] = useState<
    DropwdownValueTypes[]
  >([]);
  const [assCustomerNameDropdown, setAssCustomerNameDropdown] = useState<
    CustomerRowDataType[]
  >([]);
  const [assCustomerRowData, setAssCustomerRowData] = useState({
    id: "",
    name: "",
    customerNumber: "",
    customerLevel: "",
  });
  const [filteredColumnData, setFilteredColumnData] = useState(columnData);
  const [searchTerm, setSearchTerm] = useState("");
  const reportSchedulingOptions = ["Month", "Week", "Daily"];
  const reportTypeOptions = ["All", "Custom"];
  const [reportType, setReportType] = useState("");
  const [reportScheduling, setReportScheduling] = useState("");
  const [customerNameDropdown, setCustomerNameDropdown] = useState<
    CustomerRowDataType[]
  >([]);
  const [customerRowData, setCustomerRowData] = useState<CustomerRowDataType>({
    id: "",
    name: "",
    customerNumber: "",
    customerLevel: "",
  });
  const [customerList, setCustomerList] = useState<string[]>([]);
  const [apiId, setApiId] = useState<string>("");
  const [initialValues, setInitialValues] = useState<AddReport>({
    title: "",
    type: "",
    scheduling: "",
    todate: today,
    fromDate: afterOneYear,
    customerId: [],
    notificationEmails: [],
    templateColumn: [],
    save: true,
  });
  const [formValues, setFormValues] = useState<AddReport>({
    title: "",
    type: "",
    scheduling: "",
    todate: "",
    fromDate: "",
    customerId: [],
    notificationEmails: [],
    templateColumn: [],
    save: true,
  });
  const columns = [
    { key: "count", header: "S.", isSortable: false, className: "count" },
    {
      key: "title",
      header: "Report Title",
      isSortable: true,
      className: "dark",
    },
    {
      key: "customerName",
      header: "Customer Name",
      isSortable: true,
      className: "dark",
    },
    {
      key: "customerType",
      header: "Customer Type",
      isSortable: true,
      className: "dark",
    },
    {
      key: "createdOn",
      header: "Created On",
      isSortable: true,
      className: "dark",
    },
    {
      key: "createdBy",
      header: "Created By",
      isSortable: true,
      className: "dark",
    },
    {
      key: "scheduling",
      header: "Report Scheduling",
      isSortable: true,
      className: "dark",
    },
    {
      key: "type",
      header: "Report Type",
      isSortable: true,
      className: "dark",
    },
  ];

  const { countryCode } = useSelector((state: RootState) => state.countryCodes);

  const {
    checkInputChange,
    handleConfirmNavigation,
    handleCancelNavigation,
    showModal,
    handleUpdateNavigation,
  } = useFormChangeHandler();

  const getAgentTypes = async () => {
    const apiResponse = await getApi<any>(GET_AGENT_TYPE);
    if (apiResponse.success) {
      setAgentType(apiResponse.data);
    }
  };
  const formatToUTC = (date: string): string => {
    return moment(date).utc().format("YYYY-MM-DDTHH:mm:ss[Z]");
  };

  const getCountryCodes = async () => {
    const apiResponse = await getApi<CountryCodeApiResponse>(GET_COUNTRY_CODE);
    dispatch(setCountryCode(apiResponse?.data));
  };

  const isSaveDisabled = (value: any) => {
    let status = false;
    if (customerSelected.length === 0) status = true;
    if (value.title === "") status = true;
    if (reportType === "") status = true;
    if (value.fromDate === "") status = true;
    if (value.todate === "") status = true;
    return status;
  };

  const filteredCustomerSelected = customerSelected.filter(
    (customer) => customer.customerLevel === "second"
  );
  const isSecondLevelCustomerSelected = filteredCustomerSelected?.length > 0;

  // const updateCustomerList = (data:any) => {
  //   if(data !== null){
  //   setCustomerList((prevData)=>{
  //     return [...prevData, data];
  //     });}
  // }
  const updateCustomerList = (data: any) => {
    if (data !== null && data !== "") {
      setCustomerList((prevData) => {
        const updatedList = new Set([...prevData, data]); // Use Set to ensure uniqueness
        return Array.from(updatedList); // Convert back to array
      });
    }
  };
  const reSetDates = (values: AddReport = formValues) => {
    values.fromDate = "";
    values.todate = "";
  };

  const handleSubmit = async (values: AddReport = formValues) => {
    try {
      setLoading(true);
      let apiResponse;
      const associatedList: any = [];
      // updateCustomerList(customerRowData);
      // updateCustomerList(assCustomerRowData);
      if (associatedCustomerSelected.length > 0) {
        setCustomerList([]);
        associatedCustomerSelected.map((item) => {
          associatedList.push(item.id);
        });
      }
      if (associatedCustomerSelected.length > 0)
        values["customerId"] = associatedList;
      else values["customerId"] = customerList.filter((item) => item !== "");
      values["templateColumn"] = selectedColumns;
      values["fromDate"] = formatToUTC(
        `${moment(values.fromDate).format("YYYY-MM-DD")}T00:00:00Z`
      );
      values["todate"] = formatToUTC(
        `${moment(values.todate).format("YYYY-MM-DD")}T23:59:59`
      );
      const apiPayload = values;
      apiResponse = await postApi("/report", apiPayload);

      if (apiResponse.success) {
        console.log("Success");
        if (values["notificationEmails"].length > 0) {
          setSaveAndSend(true);
          setTimeout(() => {
            setSaveAndSend(false);
          }, 2000);
        } else {
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
          }, 2000);
        }
        setCustomerList([]);
        setSelectedColumns([
          {
            id: "N2GrsiqUJ5A",
            updatedAt: "2024-11-27T12:23:34.000Z",
            createdAt: "2024-11-27T12:00:59.000Z",
            title: "Order Number",
            key: "orderNumber",
            isActive: true,
          },
        ]);
        setCustomerRowData({
          id: "",
          name: "",
          customerNumber: "",
          customerLevel: "",
        });
        setAssCustomerRowData({
          id: "",
          name: "",
          customerNumber: "",
          customerLevel: "",
        });
        setCustomerSelected([]);
        setAssociatedCustomerSelected([]);
        setIsSelectAll(false);
        setReportType("");
        setReportScheduling("");
        values["title"] = "";
        values["notificationEmails"] = [];
        handleUpdateNavigation();
        setAskEmail(false);
      } else {
        console.log("FAILED");
      }
    } catch (error) {
      setMessage(GENERAL_ERROR_MESSAGE, "error");
    } finally {
      setLoading(false);
      fetchFilteredData();
    }
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value.toLowerCase();
    setSearchTerm(query);
    setFilteredColumnData(
      columnData.filter((data: any) => data.title.toLowerCase().includes(query))
    );
  };

  const getColumnData = async () => {
    const apiResponse = await getApi<any>(GET_REPORT_COLUMNS);
    if (apiResponse.success) {
      setColumnData(apiResponse.data);
      setFilteredColumnData(apiResponse.data);
    }
  };
  const getReports = async () => {
    const apiResponse = await getApi<any>(
      "/report?keyword=&limit=5&page=1&type=recent"
    );

    if (apiResponse.success) {
      const data = mapReportList(
        apiResponse.data.sort((a: any, b: any) => b.createdAt - a.createdAt)
      );
      setReportData(data);
    }
  };
  useEffect(() => {
    if (customerRowData && customerRowData?.id) {
      setParentCustomerId(customerRowData.id);
    }
  }, [customerRowData]);

  useEffect(() => {
    if (countryCode?.length === 0) {
      getCountryCodes();
    }
  }, [countryCode]);

  useEffect(() => {
    getColumnData();
    getReports();
  }, [data]);

  useEffect(() => {
    if (isUserOfOmniMove === true) {
      setCustomerNameDropdown(locationStateData?.addedByTenants ?? []);
    }
  }, []);

  useEffect(() => {
    if (reportType === "Custom") {
      setIsCustom(true);
      initialValues["fromDate"] = "";
      initialValues["todate"] = "";
    } else {
      setIsCustom(false);
      initialValues["fromDate"] = today;
      initialValues["todate"] = afterOneYear;
    }
  }, [reportType]);
  useEffect(() => {}, [columnData.length, filteredColumnData.length, data]);

  useEffect(() => {
    setCustomerList([]);
    // if(customerSelected !== null){
    customerSelected.map((item) => {
      const data = item.id;
      updateCustomerList(data);
    });
    // }
    // if(associatedCustomerSelected !== null){
    associatedCustomerSelected.map((item) => {
      const data = item.id;
      updateCustomerList(data);
    });
    // }
  }, [customerSelected, associatedCustomerSelected]);

  const handleCheckboxChange = (item: { id: number; title: string }) => {
    setSelectedColumns((prev: any) => {
      if (item.title === "Order Number") {
        return prev;
      }

      const alreadySelected = prev.some(
        (selected: any) => selected.id === item.id
      );

      if (alreadySelected) {
        // Remove the item
        return prev.filter((selected: any) => selected.id !== item.id);
      } else {
        // Add the item
        return [...prev, item];
      }
    });
  };

  const handleSelectAll = () => {
    const selectableItems = columnData.filter(
      (item: any) => item.title !== "Order Number"
    );

    setSelectedColumns((prev: any) => {
      const allSelected = selectableItems.every((item: any) =>
        prev.some((selected: any) => selected.id === item.id)
      );

      if (allSelected) {
        return prev.filter((item: any) => item.title === "Order Number");
      } else {
        return [
          ...selectableItems,
          ...prev.filter((item: any) => item.title === "Order Number"),
        ];
      }
    });
  };
  const isAllSelected = columnData
    .filter((item: any) => item.title !== "Order Number")
    .every((item: any) =>
      selectedColumns.some((selected) => selected.id === item.id)
    );

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={addEditAgentFormSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, values, touched, handleChange, setFieldValue }) => (
          <>
            <Form onChange={checkInputChange}>
              <div className="details">
                <div className="form form--innersec">
                  <section className="form__section">
                    <h1 className="form__section__title">Report Details</h1>
                    <div className="formRow formRow__2">
                      <div className="formCol">
                        <MultiSelectChips
                          apiUrl={isUserOfOmniMove ? "" : ADD_CUSTOMER}
                          dropdownData={customerNameDropdown}
                          selectedData={customerSelected}
                          setDropdownData={setCustomerNameDropdown}
                          setSelectedData={setCustomerSelected}
                          searchPlaceholder="Search"
                          labelTitle="Customer"
                          keyBindName="name"
                          // disable={agentRateStep !== 0}
                          limitSelection={Infinity}
                          includeAllOption={false}
                          // disableAllOption={isAgentHubAllSelected}
                          mandatory={true}
                          extendedUrl={{ type: "order" }}
                        />
                      </div>
                      <div className="formCol">
                        <MultiSelectChips
                          apiUrl={ADD_CUSTOMER}
                          dropdownData={assCustomerNameDropdown}
                          selectedData={associatedCustomerSelected}
                          setDropdownData={setAssCustomerNameDropdown}
                          setSelectedData={setAssociatedCustomerSelected}
                          // searchPlaceholder={
                          //   agentRateStep === 0 ? "Search Sub Customer" : ""
                          // }
                          labelTitle="Sub Customer"
                          keyBindName="name"
                          disable={!isSecondLevelCustomerSelected}
                          parentCustomerId={filteredCustomerSelected
                            ?.map((customer: any) => customer.id)
                            ?.join(",")}
                          limitSelection={Infinity}
                          skipApiCallWhenNoParent={true}
                        />
                      </div>
                    </div>
                    <div className="formRow formRow__2">
                      <div className="formCol">
                        <div className="form__Field">
                          <label className="form__label">
                            Report Title{" "}
                            <span className="mandatory-mark">*</span>
                          </label>
                          <Field
                            type="text"
                            id="title"
                            name="title"
                            value={values.title.trimStart()}
                            className={`form__input form__input--sm ${
                              id ? "form__input--disabled" : ""
                            }`}
                            placeholder="Report Title"
                            disabled={id}
                          />
                          {/* <span className="form__error">
                          {errors?.agentType &&
                            //touched?.agentType &&
                            errors?.agentType}
                        </span> */}
                        </div>
                      </div>
                      <div className="formCol">
                        <div className="form__Field">
                          <label className="form__label">
                            Report Scheduling
                          </label>
                          <select
                            id="scheduling"
                            name="scheduling"
                            className="form__select form__select--sm"
                            value={reportScheduling}
                            onChange={(e) => {
                              values.scheduling = e.target.value;
                              setReportScheduling(e?.target?.value);
                            }}
                          >
                            <option>-Select-</option>
                            {reportSchedulingOptions.map((item: any) => (
                              <option
                                key={`key-agent-type-${item}`}
                                value={item}
                              >
                                {item}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="formRow formRow__2">
                      <div className="formCol">
                        <div className="form__Field">
                          <label className="form__label">
                            Report Type{" "}
                            <span className="mandatory-mark">*</span>
                          </label>
                          <select
                            id="type"
                            name="type"
                            className="form__select form__select--sm"
                            value={reportType}
                            onChange={(e) => {
                              values.type = e.target.value;
                              setReportType(e.target.value);
                              if (e.target.value === "Custom")
                                reSetDates(values);
                              else {
                                values["fromDate"] = today;
                                values["todate"] = afterOneYear;
                              }
                            }}
                          >
                            <option>-Select-</option>
                            {reportTypeOptions.map((item: any) => (
                              <option
                                key={`key-report-type-${item}`}
                                value={item}
                              >
                                {item}
                              </option>
                            ))}
                          </select>
                          <span className="form__error">
                            {/* {errors?.firstName &&
                            touched?.firstName &&
                            errors?.firstName} */}
                          </span>
                        </div>
                      </div>
                    </div>
                  </section>
                  {isCustom && (
                    <section className="form__section">
                      <h1 className="form__section__title">
                        Select Date Range
                      </h1>
                      <div className="formRow formRow__1">
                        <div className="formRow formRow__4">
                          <div className="form__Field">
                            <label htmlFor="fromDate" className="form__label">
                              From Date{" "}
                              <span className="mandatory-mark">*</span>
                            </label>
                            <Field
                              type="date"
                              id="fromdate"
                              name="fromDate"
                              className={`form__input form__input--sm ${
                                id ? "form__input--disabled" : ""
                              }`}
                              max={
                                values.todate !== ""
                                  ? values.todate || undefined
                                  : maxDate
                              }
                              disabled={id}
                              onFocus={(e: any) => {
                                if (typeof e.target.showPicker === "function") {
                                  try {
                                    e.target.showPicker();
                                  } catch (error) {
                                    console.error(
                                      "showPicker requires a user gesture",
                                      error
                                    );
                                  }
                                }
                              }}
                            />
                            {/* <span className="form__error">
                        {errors?.agentType &&
                          //touched?.agentType &&
                          errors?.agentType}
                      </span> */}
                          </div>
                          <div className="form__Field">
                            <label htmlFor="toDate" className="form__label">
                              To Date <span className="mandatory-mark">*</span>
                            </label>
                            <Field
                              type="date"
                              id="todate"
                              name="todate"
                              className={`form__input form__input--sm ${
                                id ? "form__input--disabled" : ""
                              }`}
                              min={values.fromDate || undefined}
                              max={maxDate}
                              disabled={id}
                              onFocus={(e: any) => {
                                if (typeof e.target.showPicker === "function") {
                                  try {
                                    e.target.showPicker();
                                  } catch (error) {
                                    console.error(
                                      "showPicker requires a user gesture",
                                      error
                                    );
                                  }
                                }
                              }}
                            />
                          </div>
                          {/* <span className="form__error">
                        {errors?.agentType &&
                          //touched?.agentType &&
                          errors?.agentType}
                      </span> */}
                        </div>
                      </div>
                    </section>
                  )}

                  <section className="form__section">
                    <h1 className="form__section__title">
                      Select Report Columns
                    </h1>
                    <div className="formRow formRow__2">
                      <div className="formCol">
                        <SearchInput
                          type="text"
                          value={searchTerm}
                          placeholder="Search a name"
                          onChange={handleSearch}
                        />
                      </div>
                    </div>
                    <div className="formRow mb_0">
                      <div className="formCol">
                        <div className="form__Field">
                          <div className="form__checkbox form__checkbox--blue">
                            <div className="form__checkbox__option">
                              <input
                                type="checkbox"
                                className="form__checkbox__input"
                                checked={isAllSelected}
                                onChange={handleSelectAll}
                              />
                              <label
                                htmlFor="billtodickerson"
                                className="form__checkbox__label"
                              >
                                Select All
                              </label>
                            </div>
                            {filteredColumnData.map((data: any) => (
                              <div
                                className="form__checkbox__option"
                                key={data.id}
                              >
                                <input
                                  type="checkbox"
                                  id="billtodickerson"
                                  className="form__checkbox__input"
                                  checked={
                                    data.title === "Order Number" ||
                                    selectedColumns.some(
                                      (selected: any) => selected.id === data.id
                                    )
                                  }
                                  onChange={() => handleCheckboxChange(data)}
                                />
                                <label
                                  htmlFor="billtodickerson"
                                  className="form__checkbox__label"
                                >
                                  {data.title}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="btn__group">
                      <button
                        className="btn btn__white"
                        type="button"
                        onClick={() => {
                          navigate("/saved-reports");
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn btn__primary"
                        type="submit"
                        onClick={() => handleSubmit(values)}
                        disabled={isSaveDisabled(values)}
                      >
                        {"Save"}
                      </button>
                      <button
                        className="btn btn__primary"
                        type="submit"
                        onClick={() => setAskEmail(true)}
                        disabled={isSaveDisabled(values)}
                      >
                        {"Save & Send"}
                      </button>
                    </div>
                  </section>
                  {askEmail && (
                    <EmailReportModal
                      setConfirmModal={setAskEmail}
                      handleSubmit={handleSubmit}
                      values={values}
                      data={[]}
                      setData={setFormData}
                      rowData={[]}
                      rowIndex={1}
                    />
                  )}
                  {success && !loading && (
                    <SuccessModal message={"Report Saved successfully!"} />
                  )}
                  {saveAndSend && !loading && (
                    <SuccessModal
                      message={"Report Saved and Sent successfully!"}
                    />
                  )}
                  <section className="form__section">
                    <h1 className="form__section__title">Reports</h1>
                    <div className="tableWrap">
                      <div className="tableWrap__head">
                        <div className="formRow" style={{ width: "100%" }}>
                          <Table
                            columns={columns}
                            data={data}
                            setLoading={setLoading}
                            getApiData={fetchFilteredData}
                          />
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </Form>
          </>
        )}
      </Formik>
      {loading && <Loader />}

      {showModal && (
        <ConfirmModal
          confirmInfo={{
            heading: "Unsaved Changes!",
            para: "You have not saved your changes, do you want to continue",
          }}
          onConfirm={handleConfirmNavigation}
          setConfirmModal={handleCancelNavigation}
          firstMessageShow={true}
        />
      )}
    </>
  );
};

export default CreateReport; // import { useSelector, useDispatch } from "react-redux";
// import { useNavigate, useParams } from "react-router-dom";
// import { Formik, Form, Field } from "formik";
// import {
//   FilterModal,
//   MultiSelectChips,
//   MutliDropdown,
//   Table,
// } from "../../../components/shared";
// import { Loader } from "../../../components/loaders/loaders";
// import { useMessage } from "../../../context/MessageContext";
// import { AddEditAgentField } from "../../agent-management/AgentTypes";
// import { addEditAgentFormSchema } from "../../../components/schema/schema";
// import { RootState, AppDispatch } from "../../../redux/store";
// import { getApi, postApi, putApi, deleteApi } from "../../../apis/api";
// import {
//   CountryCodeApiResponse,
//   customerOrderColumns,
// } from "../../customer-management/CustomerTypes";
// import { CustomerRowDataType } from "../../Order-management/CustomerOrder/CustomerOrder-types";
// import EmailReportModal from "../../../components/modals/EmailReportModal";
// import {
//   GET_COUNTRY_CODE,
//   ADD_EDIT_AGENT,
//   GET_AGENT_TYPE,
//   GET_CUSTOMER_KEY,
//   GET_REPORT_COLUMNS,
//   ADD_CUSTOMER,
// } from "../../../apis/end-points";
// import { setCountryCode } from "../../../redux/reducer/countryCode/countryCodeSlice";
// import ConfirmModal from "../../../components/modals/ConfirmModal";
// import SearchInput from "../../../components/shared/SearchInput";
// import useFormChangeHandler from "../../../hooks/useFormChangeHandler";
// import { GENERAL_ERROR_MESSAGE } from "../../../utils/message";
// import SavedReports from "../SavedReports";
// import { AddReport } from "./CreateReport-types";
// import { template, values } from "lodash";
// import SuccessModal from "../../../components/modals/SuccessModal";
// import useSearchAndFilter from "../../../hooks/useSearchAndFilter";
// import moment from "moment";
// import { DropwdownValueTypes } from "../../agent-management/AgentList/AgentListTabs/AgentRateNew/AgentRateTypes";

// const mapReportList = (reportList: any[]) => {
//   if (reportList) {
//     return reportList?.map((item: any, index: any) => ({
//       count: item.count,
//       id: "/" + item.id,
//       createdOn: moment(item?.createdAt?.split("T")[0]).format("MM-DD-YYYY"),
//       createdAt: moment(item?.createdAt.split("T")[1], "HH:mm:ss.SSSZ").format(
//         "hh:mm A"
//       ),
//       title: item.title,
//       createdBy:
//         item.createdBy !== null
//           ? item.createdBy.firstName + " " + item.createdBy.lastName
//           : "",
//       customerName: item.customer.name,
//       customerType: item?.customer?.customerLevel
//         ? item?.customer?.customerLevel === "first"
//           ? "Direct Customer"
//           : item?.customer?.customerLevel === "second"
//           ? "Parent Customer"
//           : "Sub Customer"
//         : "",
//       scheduling: item.scheduling,
//       type: item.type,
//     }));
//   } else {
//     return [];
//   }
// };

// const CreateReport: React.FC = () => {
//   const { id } = useParams();
//   const navigate = useNavigate();
//   const { setMessage } = useMessage();
//   const dispatch: AppDispatch = useDispatch();
//   // const [loading, setLoading] = useState<boolean>(false);
//   const [confirmModal, setConfirmModal] = useState<boolean>(false);
//   const [formData, setFormData] = useState<any>({});
//   const [agentType, setAgentType] = useState<any>([]);
//   const [columnData, setColumnData] = useState<any>([]);
//   const [reportData, setReportData] = useState<any>([]);
//   const [askEmail, setAskEmail] = useState(false);
//   const [isCustom, setIsCustom] = useState(false);
//   const [tempSelectedColumns, setTempSelectedColumns] = useState([
//     {
//       id: "N2GrsiqUJ5A",
//       updatedAt: "2024-11-27T12:23:34.000Z",
//       createdAt: "2024-11-27T12:00:59.000Z",
//       title: "Order Number",
//       key: "orderNumber",
//       isActive: true,
//     },
//   ]);
//   const today = moment().format("MM-DD-YYYY");
//   const afterOneYear = moment().add(1, "year").format("MM-DD-YYYY");
//   const maxDate = moment().format("YYYY-MM-DD");
//   const [isSelectAll, setIsSelectAll] = useState(false);
//   const [selectedColumns, setSelectedColumns] = useState([
//     {
//       id: "N2GrsiqUJ5A",
//       updatedAt: "2024-11-27T12:23:34.000Z",
//       createdAt: "2024-11-27T12:00:59.000Z",
//       title: "Order Number",
//       key: "orderNumber",
//       isActive: true,
//     },
//   ]);
//   const [success, setSuccess] = useState(false);
//   const [saveAndSend, setSaveAndSend] = useState(false);
//   const { data, fetchFilteredData, loading, setLoading } = useSearchAndFilter({
//     apiUrl: "/report",
//     limit: 5,
//     mapUserData: mapReportList,
//     extentedUrl: "&type=recent",
//   });
//   const { userRole, customerId, isUserOfOmniMove, customerLevel, isCustomer } =
//     useSelector((state: RootState) => state.auth);
//   const [associatedCusReqired, setAssociatedCusReqired] = useState<boolean>(
//     !isUserOfOmniMove && isCustomer ? false : true
//   );
//   const [parentCustomerId, setParentCustomerId] = useState<string>();
//   const locationStateData = useSelector(
//     (state: RootState) => state.locationState.locationStateData
//   );
//   const [customerSelected, setCustomerSelected] = useState<
//     DropwdownValueTypes[]
//   >([]);
//   const [associatedCustomerSelected, setAssociatedCustomerSelected] = useState<
//     DropwdownValueTypes[]
//   >([]);
//   const [assCustomerNameDropdown, setAssCustomerNameDropdown] = useState<
//     CustomerRowDataType[]
//   >([]);
//   const [assCustomerRowData, setAssCustomerRowData] = useState({
//     id: "",
//     name: "",
//     customerNumber: "",
//     customerLevel: "",
//   });
//   const [filteredColumnData, setFilteredColumnData] = useState(columnData);
//   const [searchTerm, setSearchTerm] = useState("");
//   const reportSchedulingOptions = ["Month", "Week", "Daily"];
//   const reportTypeOptions = ["All", "Custom"];
//   const [reportType, setReportType] = useState("");
//   const [reportScheduling, setReportScheduling] = useState("");
//   const [customerNameDropdown, setCustomerNameDropdown] = useState<
//     CustomerRowDataType[]
//   >([]);
//   const [customerRowData, setCustomerRowData] = useState<CustomerRowDataType>({
//     id: "",
//     name: "",
//     customerNumber: "",
//     customerLevel: "",
//   });
//   const [customerList, setCustomerList] = useState<string[]>([]);
//   const [apiId, setApiId] = useState<string>("");
//   const [initialValues, setInitialValues] = useState<AddReport>({
//     title: "",
//     type: "",
//     scheduling: "",
//     todate: today,
//     fromDate: afterOneYear,
//     customerId: [],
//     notificationEmails: [],
//     templateColumn: [],
//     save: true,
//   });
//   const [formValues, setFormValues] = useState<AddReport>({
//     title: "",
//     type: "",
//     scheduling: "",
//     todate: "",
//     fromDate: "",
//     customerId: [],
//     notificationEmails: [],
//     templateColumn: [],
//     save: true,
//   });
//   const columns = [
//     { key: "count", header: "S.", isSortable: false, className: "count" },
//     {
//       key: "title",
//       header: "Report Title",
//       isSortable: true,
//       className: "dark",
//     },
//     {
//       key: "customerName",
//       header: "Customer Name",
//       isSortable: true,
//       className: "dark",
//     },
//     {
//       key: "customerType",
//       header: "Customer Type",
//       isSortable: true,
//       className: "dark",
//     },
//     {
//       key: "createdOn",
//       header: "Created On",
//       isSortable: true,
//       className: "dark",
//     },
//     {
//       key: "createdBy",
//       header: "Created By",
//       isSortable: true,
//       className: "dark",
//     },
//     {
//       key: "scheduling",
//       header: "Report Scheduling",
//       isSortable: true,
//       className: "dark",
//     },
//     {
//       key: "type",
//       header: "Report Type",
//       isSortable: true,
//       className: "dark",
//     },
//   ];

//   const { countryCode } = useSelector((state: RootState) => state.countryCodes);

//   const {
//     checkInputChange,
//     handleConfirmNavigation,
//     handleCancelNavigation,
//     showModal,
//     handleUpdateNavigation,
//   } = useFormChangeHandler();

//   const getAgentTypes = async () => {
//     const apiResponse = await getApi<any>(GET_AGENT_TYPE);
//     if (apiResponse.success) {
//       setAgentType(apiResponse.data);
//     }
//   };
//   const formatToUTC = (date: string): string => {
//     return moment(date).utc().format("YYYY-MM-DDTHH:mm:ss[Z]");
//   };

//   const getCountryCodes = async () => {
//     const apiResponse = await getApi<CountryCodeApiResponse>(GET_COUNTRY_CODE);
//     dispatch(setCountryCode(apiResponse?.data));
//   };

//   const isSaveDisabled = (value: any) => {
//     let status = false;
//     if (customerSelected.length === 0) status = true;
//     if (value.title === "") status = true;
//     if (reportType === "") status = true;
//     if (value.fromDate === "") status = true;
//     if (value.todate === "") status = true;
//     return status;
//   };

//   const filteredCustomerSelected = customerSelected.filter(
//     (customer) => customer.customerLevel === "second"
//   );
//   const isSecondLevelCustomerSelected = filteredCustomerSelected?.length > 0;

//   // const updateCustomerList = (data:any) => {
//   //   if(data !== null){
//   //   setCustomerList((prevData)=>{
//   //     return [...prevData, data];
//   //     });}
//   // }
//   const updateCustomerList = (data: any) => {
//     if (data !== null && data !== "") {
//       setCustomerList((prevData) => {
//         const updatedList = new Set([...prevData, data]); // Use Set to ensure uniqueness
//         return Array.from(updatedList); // Convert back to array
//       });
//     }
//   };
//   const reSetDates = (values: AddReport = formValues) => {
//     values.fromDate = "";
//     values.todate = "";
//   };

//   const handleSubmit = async (values: AddReport = formValues) => {
//     try {
//       setLoading(true);
//       let apiResponse;
//       const associatedList: any = [];
//       // updateCustomerList(customerRowData);
//       // updateCustomerList(assCustomerRowData);
//       if (associatedCustomerSelected.length > 0) {
//         setCustomerList([]);
//         associatedCustomerSelected.map((item) => {
//           associatedList.push(item.id);
//         });
//       }
//       if (associatedCustomerSelected.length > 0)
//         values["customerId"] = associatedList;
//       else values["customerId"] = customerList.filter((item) => item !== "");
//       values["templateColumn"] = selectedColumns;
//       values["fromDate"] = formatToUTC(
//         `${moment(values.fromDate).format("YYYY-MM-DD")}T00:00:00Z`
//       );
//       values["todate"] = formatToUTC(
//         `${moment(values.todate).format("YYYY-MM-DD")}T23:59:59`
//       );
//       const apiPayload = values;
//       apiResponse = await postApi("/report", apiPayload);

//       if (apiResponse.success) {
//         console.log("Success");
//         if (values["notificationEmails"].length > 0) {
//           setSaveAndSend(true);
//           setTimeout(() => {
//             setSaveAndSend(false);
//           }, 2000);
//         } else {
//           setSuccess(true);
//           setTimeout(() => {
//             setSuccess(false);
//           }, 2000);
//         }
//         setCustomerList([]);
//         setSelectedColumns([
//           {
//             id: "N2GrsiqUJ5A",
//             updatedAt: "2024-11-27T12:23:34.000Z",
//             createdAt: "2024-11-27T12:00:59.000Z",
//             title: "Order Number",
//             key: "orderNumber",
//             isActive: true,
//           },
//         ]);
//         setCustomerRowData({
//           id: "",
//           name: "",
//           customerNumber: "",
//           customerLevel: "",
//         });
//         setAssCustomerRowData({
//           id: "",
//           name: "",
//           customerNumber: "",
//           customerLevel: "",
//         });
//         setCustomerSelected([]);
//         setAssociatedCustomerSelected([]);
//         setIsSelectAll(false);
//         setReportType("");
//         setReportScheduling("");
//         values["title"] = "";
//         values["notificationEmails"] = [];
//         handleUpdateNavigation();
//         setAskEmail(false);
//       } else {
//         console.log("FAILED");
//       }
//     } catch (error) {
//       setMessage(GENERAL_ERROR_MESSAGE, "error");
//     } finally {
//       setLoading(false);
//       fetchFilteredData();
//     }
//   };

//   const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
//     const query = event.target.value.toLowerCase();
//     setSearchTerm(query);
//     setFilteredColumnData(
//       columnData.filter((data: any) => data.title.toLowerCase().includes(query))
//     );
//   };

//   const getColumnData = async () => {
//     const apiResponse = await getApi<any>(GET_REPORT_COLUMNS);
//     if (apiResponse.success) {
//       setColumnData(apiResponse.data);
//       setFilteredColumnData(apiResponse.data);
//     }
//   };
//   const getReports = async () => {
//     const apiResponse = await getApi<any>(
//       "/report?keyword=&limit=5&page=1&type=recent"
//     );

//     if (apiResponse.success) {
//       const data = mapReportList(
//         apiResponse.data.sort((a: any, b: any) => b.createdAt - a.createdAt)
//       );
//       setReportData(data);
//     }
//   };
//   useEffect(() => {
//     if (customerRowData && customerRowData?.id) {
//       setParentCustomerId(customerRowData.id);
//     }
//   }, [customerRowData]);

//   useEffect(() => {
//     if (countryCode?.length === 0) {
//       getCountryCodes();
//     }
//   }, [countryCode]);

//   useEffect(() => {
//     getColumnData();
//     getReports();
//   }, [data]);

//   useEffect(() => {
//     if (isUserOfOmniMove === true) {
//       setCustomerNameDropdown(locationStateData?.addedByTenants ?? []);
//     }
//   }, []);

//   useEffect(() => {
//     if (reportType === "Custom") {
//       setIsCustom(true);
//       initialValues["fromDate"] = "";
//       initialValues["todate"] = "";
//     } else {
//       setIsCustom(false);
//       initialValues["fromDate"] = today;
//       initialValues["todate"] = afterOneYear;
//     }
//   }, [reportType]);
//   useEffect(() => {}, [columnData.length, filteredColumnData.length, data]);

//   useEffect(() => {
//     setCustomerList([]);
//     // if(customerSelected !== null){
//     customerSelected.map((item) => {
//       const data = item.id;
//       updateCustomerList(data);
//     });
//     // }
//     // if(associatedCustomerSelected !== null){
//     associatedCustomerSelected.map((item) => {
//       const data = item.id;
//       updateCustomerList(data);
//     });
//     // }
//   }, [customerSelected, associatedCustomerSelected]);

//   const handleCheckboxChange = (item: { id: number; title: string }) => {
//     setSelectedColumns((prev:any) => {
//       if (item.title === "Order Number") {
//         return prev;
//       }

//       const alreadySelected = prev.some((selected:any) => selected.id === item.id);

//       if (alreadySelected) {
//         // Remove the item
//         return prev.filter((selected:any) => selected.id !== item.id);
//       } else {
//         // Add the item
//         return [...prev, item];
//       }
//     });
//   };

//   const handleSelectAll = () => {
//     const selectableItems = columnData.filter(
//       (item:any) => item.title !== "Order Number"
//     );

//     setSelectedColumns((prev:any) => {
//       const allSelected = selectableItems.every((item:any) =>
//         prev.some((selected:any) => selected.id === item.id)
//       );

//       if (allSelected) {
//         return prev.filter((item:any) => item.title === "Order Number");
//       } else {
//         return [...selectableItems, ...prev.filter((item:any) => item.title === "Order Number")];
//       }
//     });
//   };
//   const isAllSelected = columnData
//     .filter((item:any) => item.title !== "Order Number")
//     .every((item:any) =>
//       selectedColumns.some((selected) => selected.id === item.id)
//     );

//     return (
//       <>
//       <Formik
//         enableReinitialize={true}
//         initialValues={initialValues}
//         validationSchema={addEditAgentFormSchema}
//         onSubmit={handleSubmit}
//       >
//         {({ errors, values, touched, handleChange, setFieldValue }) => (
//           <>
//             <Form onChange={checkInputChange}>
//               <div className="details">
//                 <div className="form form--innersec">
//                   <section className="form__section">
//                     <h1 className="form__section__title">Report Details</h1>
//                     <div className="formRow formRow__2">
//                       <div className="formCol">
//                         <MultiSelectChips
//                           apiUrl={isUserOfOmniMove ? "" : ADD_CUSTOMER}
//                           dropdownData={customerNameDropdown}
//                           selectedData={customerSelected}
//                           setDropdownData={setCustomerNameDropdown}
//                           setSelectedData={setCustomerSelected}
//                           searchPlaceholder="Search"
//                           labelTitle="Customer"
//                           keyBindName="name"
//                           // disable={agentRateStep !== 0}
//                           limitSelection={Infinity}
//                           includeAllOption={false}
//                           // disableAllOption={isAgentHubAllSelected}
//                           mandatory={true}
//                           extendedUrl={{ type: "order" }}
//                         />
//                       </div>
//                       <div className="formCol">
//                         <MultiSelectChips
//                           apiUrl={ADD_CUSTOMER}
//                           dropdownData={assCustomerNameDropdown}
//                           selectedData={associatedCustomerSelected}
//                           setDropdownData={setAssCustomerNameDropdown}
//                           setSelectedData={setAssociatedCustomerSelected}
//                           // searchPlaceholder={
//                           //   agentRateStep === 0 ? "Search Sub Customer" : ""
//                           // }
//                           labelTitle="Sub Customer"
//                           keyBindName="name"
//                           disable={!isSecondLevelCustomerSelected}
//                           parentCustomerId={filteredCustomerSelected
//                             ?.map((customer: any) => customer.id)
//                             ?.join(",")}
//                           limitSelection={Infinity}
//                           skipApiCallWhenNoParent={true}
//                         />
//                       </div>
//                     </div>
//                     <div className="formRow formRow__2">
//                       <div className="formCol">
//                         <div className="form__Field">
//                           <label className="form__label">
//                             Report Title{" "}
//                             <span className="mandatory-mark">*</span>
//                           </label>
//                           <Field
//                             type="text"
//                             id="title"
//                             name="title"
//                             value={values.title.trimStart()}
//                             className={`form__input form__input--sm ${
//                               id ? "form__input--disabled" : ""
//                             }`}
//                             placeholder="Report Title"
//                             disabled={id}
//                           />
//                           {/* <span className="form__error">
//                           {errors?.agentType &&
//                             //touched?.agentType &&
//                             errors?.agentType}
//                         </span> */}
//                         </div>
//                       </div>
//                       <div className="formCol">
//                         <div className="form__Field">
//                           <label className="form__label">
//                             Report Scheduling
//                           </label>
//                           <select
//                             id="scheduling"
//                             name="scheduling"
//                             className="form__select form__select--sm"
//                             value={reportScheduling}
//                             onChange={(e) => {
//                               values.scheduling = e.target.value;
//                               setReportScheduling(e?.target?.value);
//                             }}
//                           >
//                             <option>-Select-</option>
//                             {reportSchedulingOptions.map((item: any) => (
//                               <option
//                                 key={`key-agent-type-${item}`}
//                                 value={item}
//                               >
//                                 {item}
//                               </option>
//                             ))}
//                           </select>
//                         </div>
//                       </div>
//                     </div>
//                     <div className="formRow formRow__2">
//                       <div className="formCol">
//                         <div className="form__Field">
//                           <label className="form__label">
//                             Report Type{" "}
//                             <span className="mandatory-mark">*</span>
//                           </label>
//                           <select
//                             id="type"
//                             name="type"
//                             className="form__select form__select--sm"
//                             value={reportType}
//                             onChange={(e) => {
//                               values.type = e.target.value;
//                               setReportType(e.target.value);
//                               if (e.target.value === "Custom")
//                                 reSetDates(values);
//                               else {
//                                 values["fromDate"] = today;
//                                 values["todate"] = afterOneYear;
//                               }
//                             }}
//                           >
//                             <option>-Select-</option>
//                             {reportTypeOptions.map((item: any) => (
//                               <option
//                                 key={`key-report-type-${item}`}
//                                 value={item}
//                               >
//                                 {item}
//                               </option>
//                             ))}
//                           </select>
//                           <span className="form__error">
//                             {/* {errors?.firstName &&
//                             touched?.firstName &&
//                             errors?.firstName} */}
//                           </span>
//                         </div>
//                       </div>
//                     </div>
//                   </section>
//                   {isCustom && (
//                     <section className="form__section">
//                       <h1 className="form__section__title">
//                         Select Date Range
//                       </h1>
//                       <div className="formRow formRow__1">
//                         <div className="formRow formRow__4">
//                           <div className="form__Field">
//                             <label htmlFor="fromDate" className="form__label">
//                               From Date{" "}
//                               <span className="mandatory-mark">*</span>
//                             </label>
//                             <Field
//                               type="date"
//                               id="fromdate"
//                               name="fromDate"
//                               className={`form__input form__input--sm ${
//                                 id ? "form__input--disabled" : ""
//                               }`}
//                               max={
//                                 values.todate !== ""
//                                   ? values.todate || undefined
//                                   : maxDate
//                               }
//                               disabled={id}
//                               onFocus={(e: any) => {
//                                 if (typeof e.target.showPicker === "function") {
//                                   try {
//                                     e.target.showPicker();
//                                   } catch (error) {
//                                     console.error(
//                                       "showPicker requires a user gesture",
//                                       error
//                                     );
//                                   }
//                                 }
//                               }}
//                             />
//                             {/* <span className="form__error">
//                         {errors?.agentType &&
//                           //touched?.agentType &&
//                           errors?.agentType}
//                       </span> */}
//                           </div>
//                           <div className="form__Field">
//                             <label htmlFor="toDate" className="form__label">
//                               To Date <span className="mandatory-mark">*</span>
//                             </label>
//                             <Field
//                               type="date"
//                               id="todate"
//                               name="todate"
//                               className={`form__input form__input--sm ${
//                                 id ? "form__input--disabled" : ""
//                               }`}
//                               min={values.fromDate || undefined}
//                               max={maxDate}
//                               disabled={id}
//                               onFocus={(e: any) => {
//                                 if (typeof e.target.showPicker === "function") {
//                                   try {
//                                     e.target.showPicker();
//                                   } catch (error) {
//                                     console.error(
//                                       "showPicker requires a user gesture",
//                                       error
//                                     );
//                                   }
//                                 }
//                               }}
//                             />
//                           </div>
//                           {/* <span className="form__error">
//                         {errors?.agentType &&
//                           //touched?.agentType &&
//                           errors?.agentType}
//                       </span> */}
//                         </div>
//                       </div>
//                     </section>
//                   )}

//                   <section className="form__section">
//                     <h1 className="form__section__title">
//                       Select Report Columns
//                     </h1>
//                     <div className="formRow formRow__2">
//                       <div className="formCol">
//                         <SearchInput
//                           type="text"
//                           value={searchTerm}
//                           placeholder="Search a name"
//                           onChange={handleSearch}
//                         />
//                       </div>
//                   </div>
//                   <div className="formRow mb_0">
//                     <div className="formCol">
//                       <div className="form__Field">
//                       <div className="form__checkbox form__checkbox--blue">
//                       <div className="form__checkbox__option" >
//                       <input
//                           type="checkbox"
//                           className="form__checkbox__input"
//                           checked={isAllSelected}
//                           onChange={handleSelectAll}
//                         />
//                         <label htmlFor="billtodickerson" className="form__checkbox__label">Select All</label>
//                         </div>
//                         {filteredColumnData.map((data: any) => (
//                         <div className="form__checkbox__option" key={data.id}>
//                             <input type="checkbox" id="billtodickerson" className="form__checkbox__input"
//                             checked={data.title === "Order Number" ||selectedColumns.some((selected: any) => selected.id === data.id)}
//                             onChange={() => handleCheckboxChange(data)} />
//                             <label htmlFor="billtodickerson" className="form__checkbox__label">{data.title}</label>
//                         </div>
//                       </div>
//                     </div>
//                     <div className="btn__group">
//                       <button
//                         className="btn btn__white"
//                         type="button"
//                         onClick={() => {
//                           navigate("/saved-reports");
//                         }}
//                       >
//                         Cancel
//                       </button>
//                       <button
//                         className="btn btn__primary"
//                         type="submit"
//                         onClick={() => handleSubmit(values)}
//                         disabled={isSaveDisabled(values)}
//                       >
//                         {"Save"}
//                       </button>
//                       <button
//                         className="btn btn__primary"
//                         type="submit"
//                         onClick={() => setAskEmail(true)}
//                         disabled={isSaveDisabled(values)}
//                       >
//                         {"Save & Send"}
//                       </button>
//                     </div>
//                   </section>
//                   {askEmail && (
//                     <EmailReportModal
//                       setConfirmModal={setAskEmail}
//                       handleSubmit={handleSubmit}
//                       values={values}
//                       data={[]}
//                       setData={setFormData}
//                       rowData={[]}
//                       rowIndex={1}
//                     />
//                   )}
//                   {success && !loading && (
//                     <SuccessModal message={"Report Saved successfully!"} />
//                   )}
//                   {saveAndSend && !loading && (
//                     <SuccessModal
//                       message={"Report Saved and Sent successfully!"}
//                     />
//                   )}
//                   <section className="form__section">
//                     <h1 className="form__section__title">Reports</h1>
//                     <div className="tableWrap">
//                       <div className="tableWrap__head">
//                         <div className="formRow" style={{ width: "100%" }}>
//                           <Table
//                             columns={columns}
//                             data={data}
//                             setLoading={setLoading}
//                             getApiData={fetchFilteredData}
//                           />
//                         </div>
//                       </div>
//                     </div>
//                   </section>
//                 </div>
//               </div>
//             </Form>
//           </>
//         )}
//       </Formik>
//       {loading && <Loader />}

//       {showModal && (
//         <ConfirmModal
//           confirmInfo={{
//             heading: "Unsaved Changes!",
//             para: "You have not saved your changes, do you want to continue",
//           }}
//           onConfirm={handleConfirmNavigation}
//           setConfirmModal={handleCancelNavigation}
//           firstMessageShow={true}
//         />
//       )}
//     </>
//   );
// };

// export default CreateReport;
