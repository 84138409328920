import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

import { validateMaskedPhoneNumber } from "../../utils/validation";
import { useMessage } from "../../context/MessageContext";
import { onInputPhNumFormat } from "../../utils/utils";

import { XCrossIcon } from "../../assets";
import { Hazmat_Message } from "../../utils/message";

interface HazardMaterial {
  itemName: string;
  packageType: string | any;
  unno: string;
  hazmatClass: string;
  properShippingName: string;
  packagingGroup: string;
  contact: string;
  phoneNumber: string;
  countryCallingCode: string;
  poison: boolean;
  isReportableQuantity: boolean;
  isMarinePollutant: boolean;
}

const mandatoryFields: (keyof HazardMaterial)[] = [
  "phoneNumber",
  "unno",
  "hazmatClass",
  "properShippingName",
  "packagingGroup",
  "contact",
];
interface AddHazmatDetailsModalType {
  setConfirmModal: (value: boolean) => void;
  data: any;
  setData: (data: any) => void;
  rowData: any;
  rowIndex: number | null;
}

/**
 * AddHazmatDetailsModal Component
 *
 * This component handles the addition and editing of Hazardous Material (Hazmat) details.
 * It provides input fields for various Hazmat properties, including contact information
 * and specific Hazmat classifications. The component validates required fields and
 * prevents submission if any mandatory fields are missing.
 *
 * @param confirmModal - Controls the visibility of the modal.
 * @param setConfirmModal - Function to set the visibility of the modal.
 * @param data - The existing data that may be updated.
 * @param setData - Function to update the data with the new or edited Hazmat information.
 * @param rowData - The current row data being edited or added.
 * @param rowIndex - The index of the row in the data array.
 */
const AddHazmatDetailsModal: React.FC<AddHazmatDetailsModalType> = (props) => {
  const { setConfirmModal, data, setData, rowData, rowIndex } = props;
  const saveType = rowData?.hazardMaterial ? true : false;
  const { countryCode } = useSelector((state: RootState) => state.countryCodes);
  const [formValues, setFormValues] = useState<HazardMaterial>({
    itemName: "",
    packageType: "",
    unno: "",
    hazmatClass: "",
    properShippingName: "",
    packagingGroup: "",
    contact: "",
    phoneNumber: "",
    countryCallingCode: "+1",
    poison: false,
    isReportableQuantity: false,
    isMarinePollutant: false,
  });
  const [errors, setErrors] = useState({
    phoneNumber: "",
  });
  const { setMessage } = useMessage();
  const [isFormChange, setIsFormChange] = useState<boolean>(false);
  const [disableAllFields, setDisableAllFields] = useState<boolean>(
    rowData?.hazmatFromOrderInfo ? rowData?.hazmatFromOrderInfo : false
  );

  /**
   * Handles the closing of the modal.
   */
  const handleClose = () => {
    setConfirmModal(false);
  };

  /**
   * Handles changes in input fields and updates the form values state.
   * Also performs validation for specific fields.
   *
   * @param e - The input change event.
   */
  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    // Prevent leading whitespace
    const trimmedValue = value.trimStart();

    setFormValues((prev) => ({
      ...prev,
      [id]: trimmedValue,
    }));
    // check error
    let error = "";
    switch (id) {
      case "phoneNumber":
        error = validateMaskedPhoneNumber(value, formValues.countryCallingCode);
        break;
      default:
        break;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [id]: error,
    }));
    setIsFormChange(true);
  };

  /**
   * Handles changes in checkbox fields and updates the form values state.
   *
   * @param e - The checkbox change event.
   */
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target as HTMLInputElement;

    setFormValues((prevValues) => ({
      ...prevValues,
      [id]: checked,
    }));
    setIsFormChange(true);
  };

  /**
   * Handles the saving of Hazmat details, updating the main data set,
   * and displaying a success message.
   */
  const handleSave = () => {
    if (Object.values(errors).some((error) => !!error) || isButtonDisabled) {
      return;
    }
    // Update data
    if (rowIndex !== null) {
      const updatedData = [...data];
      updatedData[rowIndex].hazardMaterial = formValues;
      updatedData[rowIndex].hazmat = true;
      setData(updatedData);
    }
    setMessage(
      saveType ? Hazmat_Message.SUCCESS_UPDATE : Hazmat_Message.SUCCESS_ADD,
      "success"
    );
    handleClose();
  };

  /**
   * Determines if the save button should be disabled based on form changes
   * and the completeness of mandatory fields.
   */
  const isButtonDisabled = useMemo(() => {
    const areFieldsComplete =
      !mandatoryFields.some((field) => !formValues[field]) &&
      isFormChange &&
      errors.phoneNumber === "";
    return !areFieldsComplete;
  }, [isFormChange, formValues]);

  /**
   * Loads existing Hazmat data into the form for editing if it exists,
   * or sets default values for adding new data.
   */
  useEffect(() => {
    if (rowIndex !== null) {
      if (rowData.hazmat) {
        const { hazardMaterial } = rowData;
        setFormValues({
          itemName: rowData?.description || "",
          packageType: rowData?.packageType || "",
          unno: hazardMaterial?.unno || "",
          hazmatClass: hazardMaterial?.hazmatClass || "",
          properShippingName: hazardMaterial?.properShippingName || "",
          packagingGroup: hazardMaterial?.packagingGroup || "",
          contact: hazardMaterial?.contact || "",
          phoneNumber: onInputPhNumFormat(
            hazardMaterial?.phoneNumber || "",
            hazardMaterial?.countryCallingCode || "+1"
          ),
          countryCallingCode: hazardMaterial?.countryCallingCode || "+1",
          poison: hazardMaterial?.poison || false,
          isReportableQuantity: hazardMaterial?.isReportableQuantity || false,
          isMarinePollutant: hazardMaterial?.isMarinePollutant || false,
        });
      } else {
        setFormValues({
          itemName: rowData?.description || "",
          packageType: rowData?.packageType || "",
          unno: "",
          hazmatClass: "",
          properShippingName: "",
          packagingGroup: "",
          contact: "",
          phoneNumber: "",
          countryCallingCode: "+1",
          poison: false,
          isReportableQuantity: false,
          isMarinePollutant: false,
        });
      }
    }
  }, [rowData, rowIndex]);

  return (
    <div className="modal modal--hazmatDetail modal--open">
      <div className="modal__dialog">
        <div className="modal__content modal__content--lg">
          <div className="modal__header">
            <h4 className="modal__header--title">
              {saveType ? "Edit" : "Add"} Hazmat Details
            </h4>
            <button
              className="btn btn__transparent btnClose"
              onClick={handleClose}
            >
              <XCrossIcon />
            </button>
          </div>
          <div className="modal__body">
            <div className="formRow formRow__2">
              <div className="formCol">
                <div className="form__Field">
                  <label htmlFor="itemName" className="form__label">
                    Item Name <span className="mandatory-mark">*</span>
                  </label>
                  <input
                    type="text"
                    id="itemName"
                    className="form__input form__input--sm form__input--disabled"
                    placeholder="Item Name"
                    value={formValues.itemName}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="formCol">
                <div className="form__Field">
                  <label htmlFor="packageType" className="form__label">
                    Package Type <span className="mandatory-mark">*</span>
                  </label>
                  <input
                    type="text"
                    id="packageType"
                    className="form__input form__input--sm form__input--disabled"
                    placeholder="Package Type"
                    value={typeof(formValues.packageType) === "object" ? formValues.packageType?.name : formValues.packageType}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
            <div className="formRow formRow__2">
              <div className="formCol">
                <div className="form__Field">
                  <label htmlFor="unno" className="form__label">
                    UNNO# <span className="mandatory-mark">*</span>
                  </label>
                  <input
                    type="text"
                    id="unno"
                    className={`form__input form__input--sm ${
                      disableAllFields ? "form__input--disabled" : ""
                    }`}
                    placeholder="UNNO"
                    value={formValues.unno}
                    onChange={handleFieldChange}
                    disabled={disableAllFields}
                  />
                </div>
              </div>
              <div className="formCol">
                <div className="form__Field">
                  <label htmlFor="hazmatClass" className="form__label">
                    Hazmat Class <span className="mandatory-mark">*</span>
                  </label>
                  <input
                    type="text"
                    id="hazmatClass"
                    className={`form__input form__input--sm ${
                      disableAllFields ? "form__input--disabled" : ""
                    }`}
                    placeholder="Hazmat Class"
                    value={formValues.hazmatClass}
                    onChange={handleFieldChange}
                    disabled={disableAllFields}
                  />
                </div>
              </div>
            </div>
            <div className="formRow formRow__2">
              <div className="formCol">
                <div className="form__Field">
                  <label htmlFor="properShippingName" className="form__label">
                    Proper Hazmat Shipping Name{" "}
                    <span className="mandatory-mark">*</span>
                  </label>
                  <input
                    type="text"
                    id="properShippingName"
                    className={`form__input form__input--sm ${
                      disableAllFields ? "form__input--disabled" : ""
                    }`}
                    placeholder="Proper Hazmat Shipping Name"
                    value={formValues.properShippingName}
                    onChange={handleFieldChange}
                    disabled={disableAllFields}
                  />
                </div>
              </div>
              <div className="formCol">
                <div className="form__Field">
                  <label htmlFor="packagingGroup" className="form__label">
                    Packaging Group <span className="mandatory-mark">*</span>
                  </label>
                  <input
                    type="text"
                    id="packagingGroup"
                    className={`form__input form__input--sm ${
                      disableAllFields ? "form__input--disabled" : ""
                    }`}
                    placeholder="Packaging Group"
                    value={formValues.packagingGroup}
                    onChange={handleFieldChange}
                    disabled={disableAllFields}
                  />
                </div>
              </div>
            </div>
            <div className="formRow formRow__2">
              <div className="formCol">
                <div className="form__Field">
                  <label htmlFor="contact" className="form__label">
                    Contact <span className="mandatory-mark">*</span>
                  </label>
                  <input
                    type="text"
                    id="contact"
                    className={`form__input form__input--sm ${
                      disableAllFields ? "form__input--disabled" : ""
                    }`}
                    placeholder="Contact"
                    value={formValues.contact}
                    onChange={handleFieldChange}
                    disabled={disableAllFields}
                  />
                </div>
              </div>
              <div className="formCol">
                <div className="form__Field">
                  <label htmlFor="phoneNumber" className="form__label">
                    Phone Number <span className="mandatory-mark">*</span>
                  </label>
                  <div
                    className={`form__groupField form__groupField--phonenumber ${
                      disableAllFields ? "form__groupField--disabled" : ""
                    }`}
                  >
                    <select
                      className={`form__select form__select--sm ${
                        disableAllFields ? "form__select--disabled" : ""
                      }`}
                      value={formValues.countryCallingCode}
                      onChange={(e) => {
                        setFormValues(() => ({
                          ...formValues,
                          countryCallingCode: e.target.value,
                        }));
                      }}
                      disabled={disableAllFields}
                    >
                      {countryCode?.map((item) => (
                        <option
                          key={item?.countryCallingCode}
                          value={item?.countryCallingCode}
                        >
                          {item?.countryCallingCode}
                        </option>
                      ))}
                    </select>
                    <input
                      type="text"
                      id="phoneNumber"
                      className={`form__input form__input--sm ${
                        disableAllFields ? "form__input--disabled" : ""
                      }`}
                      placeholder="Phone Number"
                      value={onInputPhNumFormat(
                        formValues.phoneNumber,
                        formValues.countryCallingCode
                      )}
                      onChange={handleFieldChange}
                      onSelect={handleFieldChange}
                      disabled={disableAllFields}
                    />
                  </div>
                  {errors.phoneNumber && (
                    <p className="form__error">{errors.phoneNumber}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="formRow">
              <div className="form__checkbox form__checkbox--gray">
                <div className="form__checkbox__option mb_0">
                  <input
                    type="checkbox"
                    id="poison"
                    className={`form__checkbox__input ${
                      disableAllFields ? "form__checkbox--disabled" : ""
                    }`}
                    checked={formValues.poison}
                    onChange={handleCheckboxChange}
                    disabled={disableAllFields}
                  />
                  <label className="form__checkbox__label" htmlFor="poison">
                    Poison
                  </label>
                </div>
                <div className="form__checkbox__option mb_0">
                  <input
                    type="checkbox"
                    id="isReportableQuantity"
                    className={`form__checkbox__input ${
                      disableAllFields ? "form__checkbox--disabled" : ""
                    }`}
                    checked={formValues.isReportableQuantity}
                    onChange={handleCheckboxChange}
                    disabled={disableAllFields}
                  />
                  <label
                    className="form__checkbox__label"
                    htmlFor="isReportableQuantity "
                  >
                    Is Reportable Quantity
                  </label>
                </div>
                <div className="form__checkbox__option mb_0">
                  <input
                    type="checkbox"
                    id="isMarinePollutant"
                    className={`form__checkbox__input ${
                      disableAllFields ? "form__checkbox--disabled" : ""
                    }`}
                    checked={formValues.isMarinePollutant}
                    onChange={handleCheckboxChange}
                    disabled={disableAllFields}
                  />
                  <label
                    className="form__checkbox__label"
                    htmlFor="isMarinePollutant "
                  >
                    Is Marine Pollutant
                  </label>
                </div>
              </div>
            </div>
          </div>
          {!disableAllFields && (
            <div className="modal__footer">
              <div className="btn__group">
                <button className="btn btn__white" onClick={handleClose}>
                  Cancel
                </button>
                <button
                  className="btn btn__primary"
                  onClick={handleSave}
                  disabled={isButtonDisabled}
                >
                  {saveType ? "Update" : "Save"}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="modal__backdrop"></div>
    </div>
  );
};

export default AddHazmatDetailsModal;
