/***** User end points ******/
export const Login_USER_API = "/user/auth/login";
export const Login_OTP_VERIFY_API = "/user/auth/verify-otp";
export const USER_UPDATE_PASSWORD = "/user/change-password/";
export const Set_USER_PASSWORD = "/user/auth/set-password";
export const ADD_USER = "/user/auth/register";
export const VERIFY_EMAIL = "/user/auth/forgot-password";
export const RESET_PASSWORD = "/user/auth/reset-password";
export const GET_PROFILE_DATA = "/user/";
export const PHONE_OTP_VERIFY = "/user/verify-phone/";
export const RESEND_OTP = "/user/auth/resend-otp";
export const GET_USER_ROLES = "/user/role";
export const CHECK_INVITATION_TOKEN = "/user/auth/check-invitation";
export const GET_COUNTRY_CODE = "/user/country-list";

/***** Customer end points ******/
export const ADD_CUSTOMER = "/customer/";
export const ADD_CUSTOMER_FORM = "/customer/form";
export const GET_DATA_FROM_ZIPCODE = "/customer/zip-details/";
export const ADD_CUSTOMER_USER = "/customer/customer-user/";
export const GET_PARENT_CUSTOMER_DATA = "/user/auth/parent-customer";
export const SWITCH_PARENT_CUSTOMER = "/user/auth/switch-user";
export const CUSTOMER_MARKUP = "/customer/markup";
export const CUSTOMER_MARKUP_AGENT = "/customer/markup/agent-markup/";
export const CUSTOMER_MARKUP_SERVICES = "/customer/markup/service-markup/";
export const CUSTOMER_CHARGES_ADDITIONALS_DATA = "/customer/charges/additional";

/***** Agent end points ******/
export const ADD_EDIT_AGENT = "/agent/";
export const GET_AGENT_TYPE = "/agent/agent-type";
export const GET_AGENT_SERVICE_LIST = "/agent/service";
export const GET_AGENT_DETAILS = "/agent/agent-rate";
export const GET_FSC_LOCATIONS = "/agent/fsc-location";
export const AGENT_HUB_DATA = "/agent/agent-hub/";
export const AGENT_HUB_SERVICE_ZIP = "/agent/agent-hub/service-zip";
export const AGENT_HUB_CUSTOMER = "/agent/agent-hub/customer";
export const ORDER_STATUS = "/order/order-status";

/*** Report end points *****/
export const GET_REPORT_COLUMNS = "/report/column-list";

/***** Dispatch board end points ******/
export const DISPATCH_BOARD_ADD_NOTE = "/order/dispatch-board/note";

/***** External end points ******/
export const GET_CUSTOMER_KEY = "/external/v1/credential";

/***** Xcelerator end points ******/
export const GET_XCELERATOR_DATA = "/agent/xcelerator/";

/***** Resend end points ******/
export const RESEND_INVITATION_KEY = "/user/auth/resend-invitation";

/***** Invoice End Points ******/
export const GET_SAVE_CUSTOMER_INVOICE_GROUPS_LIST = "/order/invoice-group";

export const GET_SAVE_ORDER_INVOICE_LIST = "/order/invoice";
export const GET_ORDER_INVOICE_ORDER_DATA = "/order/invoice/group-orders";
export const GET_ORDER_INVOICE_CALCULATION_DATA = "/order/invoice/calc";

/***** Resend end points ******/
export const DASHBOARD_API = "/order/dashboard/kpis";
