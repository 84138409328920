import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import {
  setPage,
  setTotalItems,
  setTotalPages,
  setLimit,
} from "../redux/reducer/pagination/paginationSlice";
import { useMessage } from "../context/MessageContext";
import { getApi, postApi, putApi } from "../apis/api";
import { useNavigate } from "react-router-dom";
import { GENERAL_ERROR_MESSAGE } from "../utils/message";

interface useSearchAndFilterTypes {
  apiUrl: string;
  limit: number;
  mapUserData: (ApiResponse: any[]) => any[];
  extentedUrl?: string | "";
  customerUrlId?: string;
  agentHubUrlId?: string;
  fscLocationUrlId?: string;
  locationId?: string;
  tenantId?: string;
  xceleratorId?: string;
  parentCustomerId?: string | "";
  customerIds?: string;
  agentHubIds?: string;
  locationName?: string;
  type?: string;
  invoiceGroupId?: string;
  isPostApi?: boolean;
}
export interface DropwdownInitailValueTypes {
  id: string;
  name: string;
}

const dropwdownInitailValue: DropwdownInitailValueTypes = {
  id: "",
  name: "",
};
interface Pagination {
  currentPage: number;
  totalPages: number;
  previousPage: number | null;
  nextPage: number | null;
  totalItems: number;
}
interface GetApiResponse<T> {
  success: boolean;
  data?: T;
  error?: {
    message: string;
  };
  pagination?: Pagination;
}

interface columnData {
  key: string;
  label: string;
  id?: string;
}
/**
 * Custom hook for handling search, filter, pagination, and API interactions in a table or list.
 *
 * @template T - The type of data being fetched and managed.
 *
 * @param apiUrl - The URL to fetch data from.
 * @param limit - The maximum number of items to fetch.
 * @param mapUserData - Function to map the fetched data.
 *
 * @returns data - The fetched data for display in the table.
 * @returns setData - Function to manually set the fetched data.
 * @returns keyword - The current search keyword.
 * @returns currentPage - The current page number in the pagination.
 * @returns loading - Boolean indicating if data is being loaded.
 * @returns setLoading - Function to set the loading state.
 * @returns handleKeywordChange - Function to handle changes in the search input and debounce API calls.
 * @returns fetchFilteredData - Function to fetch data based on the current filters and pagination.
 * @returns handleSaveUpdate - Function to save or update data via API, with optional navigation.
 * @returns isShowFilterModal - Boolean indicating if the filter modal is currently shown.
 * @returns closeOpenfilterModal - Function to toggle the visibility of the filter modal.
 * @returns selectStatus - The currently selected status for filtering.
 * @returns setSelectStatus - Function to set the selected status for filtering.
 * @returns selectedRoles - The currently selected roles for filtering.
 * @returns setSelectedRoles - Function to set the selected roles for filtering.
 * @returns rolesDropdownData - The data used in the roles dropdown filter.
 * @returns setRolesDropdownData - Function to set the data used in the roles dropdown filter.
 * @returns orderStatusSelected - The currently selected order status for filtering.
 * @returns setOrderStatusSelected - Function to set the selected order status for filtering.
 * @returns orderTypeSelected - The currently selected order type for filtering.
 * @returns setOrderTypeSelected - Function to set the selected order type for filtering.
 * @returns agentTypeSelected - The currently selected order type for filtering.
 * @returns setAgentTypeSelected - Function to set the selected order type for filtering.
 * @returns hubSelected - The currently selected hub for filtering.
 * @returns setHubSelected - Function to set the selected hub for filtering.
 * @returns serviceSelected - The currently selected service for filtering.
 * @returns setServiceSelected - Function to set the selected service for filtering.
 * @returns orderStatusDropdownData - The data used in the order status dropdown filter.
 * @returns setOrderStatusDropdownData - Function to set the data used in the order status dropdown filter.
 * @returns orderTypeDropdownData - The data used in the order type dropdown filter.
 * @returns setOrderTypeDropdownData - Function to set the data used in the order type dropdown filter.
 * @returns agentTypeDropdownData - The data used in the order type dropdown filter.
 * @returns setAgentTypeDropdownData - Function to set the data used in the order type dropdown filter.
 * @returns hubDropdownData - The data used in the hub dropdown filter.
 * @returns setHubDropdownData - Function to set the data used in the hub dropdown filter.
 * @returns serviceDropdownData - The data used in the service dropdown filter.
 * @returns setServiceDropdownData - Function to set the data used in the service dropdown filter.
 */
const useSearchAndFilter = <T extends unknown>({
  apiUrl,
  limit = 15,
  mapUserData,
  extentedUrl = "",
  customerUrlId,
  agentHubUrlId,
  fscLocationUrlId,
  tenantId,
  xceleratorId,
  parentCustomerId,
  customerIds,
  agentHubIds,
  locationId,
  locationName,
  type,
  invoiceGroupId,
  isPostApi,
}: useSearchAndFilterTypes) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setMessage } = useMessage();
  const currentPage = useSelector(
    (state: RootState) => state.pagination.currentPage
  );
  const [data, setData] = useState<T[]>([]);
  const [keyword, setKeyword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [originalPage, setOriginalPage] = useState<number>(1);
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
  // State variables for handling filter modals and dropdown data
  const [isShowFilterModal, setIsShowFilterModal] = useState<boolean>(false);
  const [selectStatus, setSelectStatus] = useState<DropwdownInitailValueTypes>(
    dropwdownInitailValue
  );
  const [customerSelectedLevel, setCustomerSelectedLevel] =
    useState<DropwdownInitailValueTypes>(dropwdownInitailValue);
  const [orderStatusSelected, setOrderStatusSelected] = useState<
    DropwdownInitailValueTypes[]
  >([]);
  const [customerTypeLevel, setCustomerTypeLevel] = useState<
    DropwdownInitailValueTypes[]
  >([]);
  const [orderTypeSelected, setOrderTypeSelected] = useState<
    DropwdownInitailValueTypes[]
  >([]);

  const [agentTypeSelected, setAgentTypeSelected] = useState<
    DropwdownInitailValueTypes[]
  >([]);
  const [hubSelected, setHubSelected] = useState<DropwdownInitailValueTypes[]>(
    []
  );
  const [serviceSelected, setServiceSelected] = useState<
    DropwdownInitailValueTypes[]
  >([]);
  const [selectedRoles, setSelectedRoles] = useState<
    DropwdownInitailValueTypes[]
  >([]);
  const [rolesDropdownData, setRolesDropdownData] = useState<
    DropwdownInitailValueTypes[]
  >([]);
  const [orderStatusDropdownData, setOrderStatusDropdownData] = useState<
    DropwdownInitailValueTypes[]
  >([]);
  const [serviceLevelDropdownData, setServiceLevelDropdownData] = useState<
    DropwdownInitailValueTypes[]
  >([]);
  const [customerLevelDropdownData, setCustomerLevelDropdownData] = useState<
    DropwdownInitailValueTypes[]
  >([]);
  const [orderTypeDropdownData, setOrderTypeDropdownData] = useState<
    DropwdownInitailValueTypes[]
  >([]);
  const [agentTypeDropdownData, setAgentTypeDropdownData] = useState<
    DropwdownInitailValueTypes[]
  >([]);
  const [hubDropdownData, setHubDropdownData] = useState<
    DropwdownInitailValueTypes[]
  >([]);
  const [serviceDropdownData, setServiceDropdownData] = useState<
    DropwdownInitailValueTypes[]
  >([]);
  const [filterApplied, setFilterApplied] = useState<boolean>(false);
  const [orderNumber, setOrderNumber] = useState<string>("");
  const [consigneeName, setConsigneeName] = useState<string>("");
  const [customerName, setCustomerName] = useState<string>("");

  /**
   * Fetches filtered data from the API based on the current page and keyword.
   * Updates the Redux state for pagination and maps the response data.
   *
   * @param {number} page - The page number to fetch.
   */
  const fetchFilteredData = async (
    page: number = 1,
    selectStatus?: DropwdownInitailValueTypes,
    selectedRoles?: DropwdownInitailValueTypes[],
    orderStatusSelected?: DropwdownInitailValueTypes[],
    orderTypeSelected?: DropwdownInitailValueTypes[],
    agentTypeSelected?: DropwdownInitailValueTypes[],
    hubSelected?: DropwdownInitailValueTypes[],
    serviceSelected?: DropwdownInitailValueTypes[],
    customerSelectedLevel?: DropwdownInitailValueTypes,
    customerTypeLevel?: DropwdownInitailValueTypes[],
    orderNumber?: string,
    consigneeName?: string,
    customerName?: string
  ) => {
    if (!apiUrl || extentedUrl.includes("undefined")) {
      return;
    }

    try {
      setLoading(true);
      let url = `${apiUrl}?limit=${limit}&page=${page}`;
      if (keyword.trim() !== "") {
        url += `&keyword=${keyword}`;
      }
      if (extentedUrl) {
        url += extentedUrl;
      }
      if (customerUrlId) {
        url += `&customerId=${customerUrlId}`;
      }
      if (orderNumber) {
        url += `&orderNumber=${orderNumber}`;
      }
      if (consigneeName) {
        url += `&consigneeName=${consigneeName}`;
      }
      if (customerName) {
        url += `&customerName=${customerName}`;
      }
      if (agentHubUrlId) {
        url += `&agentHubId=${agentHubUrlId}`;
      }
      if (fscLocationUrlId) {
        url += `&fscLocationId=${fscLocationUrlId}`;
      }
      if (locationId) {
        url += `&locationId=${locationId}`;
      }
      if (locationName) {
        url += `&locationName=${locationName}`;
      }
      if (tenantId) {
        url += `&tenantId=${tenantId}`;
      }
      if (xceleratorId) {
        url += `&xceleratorId=${xceleratorId}`;
      }
      if (parentCustomerId) {
        url += `&parentCustomerId=${parentCustomerId}`;
      }
      if (invoiceGroupId) {
        url += `&invoiceGroupId=${invoiceGroupId}`;
      }
      if (selectStatus?.id) {
        url += `&status=${selectStatus.id}`;
      }
      if (customerSelectedLevel?.id) {
        url += `&customerType=${customerSelectedLevel.id}`;
      }
      if (selectedRoles && selectedRoles.length > 0) {
        url += `&roleIds=${selectedRoles?.map((role) => role.id)?.join(",")}`;
      }
      if (orderStatusSelected && orderStatusSelected.length > 0) {
        url += `&statusTypeIds=${orderStatusSelected
          ?.map((role) => role.id)
          ?.join(",")}`;
      }
      if (orderTypeSelected && orderTypeSelected.length > 0) {
        url += `&orderTypeIds=${orderTypeSelected
          ?.map((role) => role.id)
          ?.join(",")}`;
      }
      if (agentTypeSelected && agentTypeSelected.length > 0) {
        url += `&agentTypeId=${agentTypeSelected
          ?.map((role) => role.id)
          ?.join(",")}`;
      }
      if (hubSelected && hubSelected.length > 0) {
        url += `&hubIds=${hubSelected?.map((role) => role.id)?.join(",")}`;
      }
      if (serviceSelected && serviceSelected.length > 0) {
        url += `&serviceIds=${serviceSelected
          ?.map((role) => role.id)
          ?.join(",")}`;
      }
      if (customerTypeLevel && customerTypeLevel.length > 0) {
        url += `&customerType=${customerTypeLevel
          ?.map((role) => role.id)
          ?.join(",")}`;
      }
      if (customerIds) {
        url += `&customerIds=${customerIds}`;
      }
      if (agentHubIds) {
        url += `&agentHubIds=${agentHubIds}`;
      }
      if (type) {
        url += `&type=${type}`;
      }
      const response = await getApi(url);
      if (response.success) {
        const userData: any = response.data || [];

        const paginationData = {
          totalItems: response.pagination?.totalItems || 0,
          totalPages: response.pagination?.totalPages || 1,
          currentPage: response.pagination?.currentPage || 1,
        };
        dispatch(setTotalItems(paginationData.totalItems));
        dispatch(setTotalPages(paginationData.totalPages));
        dispatch(setLimit(limit));
        if (paginationData.currentPage !== currentPage) {
          dispatch(setPage(paginationData.currentPage));
        }
        userData.forEach((item: any, index: number) => {
          item.count = (page - 1) * limit + index + 1;
        });
        const mappedData = mapUserData(userData);
        setData(mappedData);
      } else {
        setMessage(response.error?.message || GENERAL_ERROR_MESSAGE, "error");
      }
    } catch (error) {
      console.log("error", error);
      setMessage(GENERAL_ERROR_MESSAGE, "error");
    } finally {
      setLoading(false);
    }
  };

  /**
   * Handles changes to the search keyword input and triggers a data fetch.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} e - The input change event.
   */
  const handleKeywordChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setKeyword(value);
      if (value.trim() === "") {
        dispatch(setPage(originalPage));
      } else if (value.trim().length === 1) {
        setOriginalPage(currentPage);
      }
    },
    [dispatch, originalPage, currentPage]
  );

  /**
   * Handles saving or updating data via API, with optional navigation upon success.
   *
   * @param {string} apiUrl - The API endpoint URL.
   * @param {any[]} apiPayload - The data to be sent to the API.
   * @param {string} type - The type of operation ("Create" or "Update").
   * @param {string} successMessage - The success message to display.
   * @param {string} navigationPath - The path to navigate to after success.
   */
  const handleSaveUpdate = async (
    apiUrl: string,
    apiPayload: any[],
    type: string,
    successMessage: string,
    navigationPath: string
  ) => {
    try {
      setLoading(true);
      const response =
        type === "Create"
          ? await postApi(apiUrl, apiPayload)
          : await putApi(apiUrl, apiPayload);
      if (response.success) {
        setMessage(successMessage, "success");
        if (navigationPath) {
          navigate(navigationPath);
        }
        fetchFilteredData(
          currentPage,
          selectStatus,
          selectedRoles,
          orderStatusSelected,
          orderTypeSelected,
          agentTypeSelected,
          hubSelected,
          serviceSelected,
          customerSelectedLevel,
          customerTypeLevel,
          orderNumber,
          consigneeName,
          customerName
        );
      } else {
        setMessage(response.error?.message || GENERAL_ERROR_MESSAGE, "error");
      }
    } catch (error) {
      setMessage(GENERAL_ERROR_MESSAGE, "error");
    } finally {
      setLoading(false);
    }
  };

  /**
   * Toggles the visibility of the filter modal.
   */
  const closeOpenfilterModal = useCallback(() => {
    setIsShowFilterModal(!isShowFilterModal);
  }, [isShowFilterModal]);

  useEffect(() => {
    if (isFirstRender) {
      fetchFilteredData(
        1,
        selectStatus,
        selectedRoles,
        orderStatusSelected,
        orderTypeSelected,
        agentTypeSelected,
        hubSelected,
        serviceSelected,
        customerSelectedLevel,
        customerTypeLevel,
        orderNumber,
        consigneeName,
        customerName
      );
      setIsFirstRender(false);
    } else {
      const debounceFetch = setTimeout(() => {
        if (keyword) {
          dispatch(setPage(1)); // Set the page to 1 before making the API call
          fetchFilteredData(1);
        } else {
          fetchFilteredData(
            currentPage,
            selectStatus,
            selectedRoles,
            orderStatusSelected,
            orderTypeSelected,
            agentTypeSelected,
            hubSelected,
            serviceSelected,
            customerSelectedLevel,
            customerTypeLevel,
            orderNumber,
            consigneeName,
            customerName
          );
        }
      }, 1000);
      return () => clearTimeout(debounceFetch);
    }
  }, [keyword]);

  const refreshPage = () => {
    if (!isFirstRender) {
      fetchFilteredData();
    }
  };

  /**
   * Handles changes to the search keyword input and triggers a data fetch.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} e - The input change event.
   */
  useEffect(() => {
    if (!isFirstRender) {
      fetchFilteredData(
        currentPage,
        selectStatus,
        selectedRoles,
        orderStatusSelected,
        orderTypeSelected,
        agentTypeSelected,
        hubSelected,
        serviceSelected,
        customerSelectedLevel,
        customerTypeLevel,
        orderNumber,
        consigneeName,
        customerName
      );
    }
  }, [currentPage]);

  return {
    data,
    setData,
    keyword,
    currentPage,
    loading,
    setLoading,
    handleKeywordChange,
    fetchFilteredData,
    handleSaveUpdate,
    isShowFilterModal,
    closeOpenfilterModal,
    selectStatus,
    setSelectStatus,
    selectedRoles,
    setSelectedRoles,
    rolesDropdownData,
    setRolesDropdownData,
    orderStatusSelected,
    setOrderStatusSelected,
    orderStatusDropdownData,
    setOrderStatusDropdownData,
    serviceLevelDropdownData,
    setServiceLevelDropdownData,
    orderTypeSelected,
    setOrderTypeSelected,
    orderTypeDropdownData,
    setOrderTypeDropdownData,
    agentTypeSelected,
    setAgentTypeSelected,
    agentTypeDropdownData,
    setAgentTypeDropdownData,
    hubSelected,
    setHubSelected,
    hubDropdownData,
    setHubDropdownData,
    serviceSelected,
    setServiceSelected,
    serviceDropdownData,
    setServiceDropdownData,
    customerSelectedLevel,
    setCustomerSelectedLevel,
    customerLevelDropdownData,
    setCustomerLevelDropdownData,
    customerTypeLevel,
    setCustomerTypeLevel,
    refreshPage,
    filterApplied,
    setFilterApplied,
    consigneeName,
    setConsigneeName,
    customerName,
    setCustomerName,
    orderNumber,
    setOrderNumber,
  };
};

export default useSearchAndFilter;
